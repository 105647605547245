<template>
  <div class="mobile-accordion" @click="toggleContent">
    <h2 class="mobile-accordion__title title is-2">
      <span>{{ title }}</span>
      <button
        :title="titleAttr"
        :aria-controls="titleId"
        :aria-expanded="expanded.toString()"
        class="mobile-accordion__button is-hidden-tablet"
        :class="{ 'expanded': expanded }"
      >
        <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
      </button>
    </h2>
    <div
      :id="titleId"
      class="mobile-accordion__content"
      :class="{ 'is-hidden-mobile': !expanded }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/components/svg/ArrowIcon.vue';

export default {
  name: 'MobileAccordion',
  components: { ArrowIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    titleId() {
      return `${this.title.toLowerCase().replace(' ', '-')}-accordion-content`;
    },
    titleAttr() {
      return `Toggle accordion content for ${this.title}`;
    },
  },
  methods: {
    toggleContent() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
