<template>
  <article class="result">
    <div class="columns">
      <div class="column is-4">
        <a :href="url" class="result__img">
          <img
            v-if="image"
            :src="image.url"
            :alt="title"
            :width="image.width"
            :height="image.height"
            loading="lazy" />
        </a>
      </div>
      <div class="column is-8">
        <a :href="url" class="result__title">
          <h3 class="title is-3-to-2 is-insight">{{ title }}</h3>
        </a>
        <p class="is-small is-pulled-right is-hidden-mobile">Insight</p>
        <p class="result__date is-small">{{ publishedDate }}</p>
        <div v-html="blurb"></div>
        <a :href="url" class="result__cta">Read more</a>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios';

export default {
  name: 'insight-result',
  props: {
    pageId: Number,
  },
  data() {
    return {
      blurb: '',
      image: null,
      publishedDate: '',
      title: '',
      url: '',
    };
  },
  mounted() {
    this.loadInsight();
  },
  methods: {
    loadInsight() {
      axios.get(`${process.env.VUE_APP_API_URL}pages/${this.pageId}`)
        .then((response) => {
          this.blurb = response.data.blurb;
          this.image = response.data.card_image;
          this.publishedDate = response.data.published_date;
          this.title = response.data.title;
          this.url = response.data.url;
        })
        .catch((error) => {
          console.error(`Error getting insight data: ${error}`);
        });
    },
  },
};
</script>
