<template>
  <a :href="url" target="_blank" class="download" :aria-label="`Download file: ${title}`">
      <div class="download__icon">
        <div class="icon icon--download" aria-hidden="true">
          <download-icon></download-icon>
        </div>
      </div>
      <div class="download__content">
        <p class="download__name">
          <span>{{ title }}</span>
        </p>
        <p class="is-small has-text-grey-dark download__category">
          {{ type }}
        </p>
        <p class="is-small has-text-grey-dark download__filesize">
          {{ size }}
        </p>
        <p class="is-small has-text-grey-dark download__filetype">
          {{ format }}
        </p>
      </div>
    </a>
</template>

<script>
import DownloadIcon from '@/components/svg/DownloadIcon.vue';

export default {
  name: 'download',
  components: { DownloadIcon },
  props: ['title', 'url', 'type', 'size', 'format'],
};
</script>
