<template>
  <a class="shop-link module" :href="url" target="_blank">
    <div class="shop-link__image">
      <img
        :src="imageUrl"
        :alt="imageTitle"
        :width="imageWidth"
        :height="imageHeight"
        loading="lazy"
      >
    </div>
    <div class="shop-link__content">
      <h3 class="title is-3">{{ title }}</h3>
      <p class="shop-link__category has-text-grey-dark">{{ category }}</p>
      <p class="shop-link__price is-regular">{{ productPrice }}</p>
      <p class="shop-link__cta is-regular">View in ADF Shop</p>
    </div>
  </a>
</template>

<script>
import axios from 'axios';

export default {
  name: 'shop-product',
  props: {
    productId: Number,
  },
  computed: {
    productPrice() {
      if (this.price) {
        return `$${this.price} (inc. GST)`;
      }
      return '';
    },
  },
  data() {
    return {
      category: '',
      imageTitle: '',
      imageUrl: '',
      imageWidth: null,
      imageHeight: null,
      price: null,
      title: '',
      url: '',
    };
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_API_URL}shop-products/${this.productId}/`)
      .then((response) => {
        const product = response.data;

        this.category = product.category;
        this.imageTitle = product.image.title;
        this.imageUrl = product.image.url;
        this.imageWidth = Math.round(product.image.width / 2);
        this.imageHeight = Math.round(product.image.height / 2);
        this.price = product.price;
        this.title = product.title;
        this.url = product.url;
      })
      .catch((error) => {
        console.error(`Error getting product data: ${error}`);
      });
  },
};
</script>
