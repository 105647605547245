<template>
  <div class="drugs__filter">
    <label for="drug-filter">View by drug type</label>
    <div class="select">
      <select id="drug-filter" v-model="selectedFilter" @change="filterSelected">
        <option value="">All Drugs</option>
        <option v-for="type in drugTypes" :key="type.id" :value="type.name">
          {{ type.name }}
        </option>
      </select>
      <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '../svg/ArrowIcon.vue';

export default {
  name: 'drug-filter',
  components: { ArrowIcon },
  props: ['drugTypes'],
  data() {
    return {
      selectedFilter: '',
    };
  },
  methods: {
    /**
     * emit selected filter.
     */
    filterSelected() {
      this.$emit('filter-selected', this.selectedFilter);
    },
  },
};
</script>
