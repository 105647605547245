<template>
  <li>
    <button
      v-if="parent"
      class="sidenav__toggle"
      :class="{ 'is-active': expanded }"
      :title="titleText"
      :aria-controls="ariaControls"
      :aria-expanded="expanded.toString()"
      @click="toggleMenu"
    ></button>
    <a
      :href="url"
      class="sidenav__link"
      :class="{ 'is-active': active }"
      :aria-current="ariaCurrent"
    >
      {{ title }}
    </a>
    <template v-if="parent">
      <ul class="sidenav__submenu" :id="ariaControls">
        <slot></slot>
      </ul>
    </template>
  </li>
</template>

<script>
export default {
  name: 'SidenavBlock',
  props: {
    title: String,
    slug: String,
    url: String,
    active: Boolean,
    parent: Boolean,
    ancestor: Boolean,
  },
  data() {
    return {
      expanded: this.ancestor || this.active,
    };
  },
  computed: {
    titleText() {
      return `Expand submenu for ${this.title}`;
    },
    ariaCurrent() {
      return this.active ? 'page' : '';
    },
    ariaControls() {
      return `sidenav-${this.slug}`;
    },
  },
  methods: {
    toggleMenu() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
