<template>
  <div class="overlay__wrapper">
    <button
      class="drug-header__enlarge"
      title="view full drug image in overlay"
      :style="headerImage"
      @click.prevent="toggleOverlay"
      ref="button"
    >
      <span class="icon icon--enlarge" aria-hidden="true"><enlarge-icon></enlarge-icon></span>
    </button>
    <div
      class="overlay"
      :class="{ 'is-active': overlayActive, 'is-visible': overlayVisible }"
      role="dialog"
      aria-label="overlay"
      aria-describedby="overlay-description"
      aria-modal="true"
      tabindex="0"
      @click.prevent="closeOverlay"
    >
      <div class="container overlay_container">
        <div class="overlay_content" ref="content" @transitionend="hideOverlay">
          <button class="overlay_close" title="close full drug image overlay">
            <span class="icon icon--close" aria-hidden="true"><close-icon></close-icon></span>
          </button>
          <slot></slot>
          <p class="is-sr-only" aria-label="overlay-description">
            Expanded drug image for {{ title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import CloseIcon from '@/components/svg/CloseIcon.vue';
import EnlargeIcon from '@/components/svg/EnlargeIcon.vue';

export default {
  name: 'drug-overlay',
  components: { CloseIcon, EnlargeIcon },
  props: {
    title: String,
    header_image: String,
  },
  computed: {
    headerImage() {
      return `background-image: url('${this.header_image}')`;
    },
  },
  data() {
    return {
      overlayActive: false,
      overlayVisible: false,
    };
  },
  mounted() {
    document.onkeydown = (e) => {
      if (document.documentElement.classList.contains('has-overlay')) {
        const evt = e || window.event;
        if (evt.keyCode === 27 || evt.key === 'Escape' || evt.key === 'Esc') {
          this.toggleOverlay();
        }
      }
    };
  },
  methods: {
    /**
     * Prevent double click on overlay bouncing image
     */
    closeOverlay() {
      if (this.overlayVisible) {
        this.toggleOverlay();
      }
    },
    /**
     * Toggle display and visiblity of overlay
     */
    toggleOverlay() {
      const { content } = this.$refs;
      const buttonRect = this.$refs.button.getBoundingClientRect();

      if (this.overlayVisible) {
        // Grab content size so we can transition away from it
        const contentRect = content.getBoundingClientRect();

        // Hard set content
        content.style.top = `${contentRect.top}px`;
        content.style.height = `${contentRect.height}px`;
        content.style.left = `${contentRect.left}px`;
        content.style.width = `${contentRect.width}px`;

        // Set opacity to 0
        this.overlayVisible = false;

        setTimeout(() => {
          // With that done, set size back to button
          content.style.top = `${buttonRect.top}px`;
          content.style.height = `${buttonRect.height}px`;
          content.style.left = `${buttonRect.left}px`;
          content.style.width = `${buttonRect.width}px`;
        }, 5);
      } else {
        document.scrollingElement.classList.add('has-overlay');
        this.overlayActive = true;
        Vue.nextTick(() => {
          // Grab content size now that overlay has display
          const contentRect = content.getBoundingClientRect();

          // Set content size to match button
          content.style.top = `${buttonRect.top}px`;
          content.style.height = `${buttonRect.height}px`;
          content.style.left = `${buttonRect.left}px`;
          content.style.width = `${buttonRect.width}px`;

          // Set opacity to 1
          this.overlayVisible = true;
          setTimeout(() => {
            // Once that is done, set back to initial values to show transition
            content.style.top = `${contentRect.top}px`;
            content.style.height = `${contentRect.height}px`;
            content.style.left = `${contentRect.left}px`;
            content.style.width = `${contentRect.width}px`;
          }, 5);
        });
      }
    },
    /**
     * Set overlay to display: none after transiting out
     * and remove transition values
     */
    hideOverlay() {
      if (!this.overlayVisible) {
        this.overlayActive = false;
        document.scrollingElement.classList.remove('has-overlay');
      }
      // Set values to null to restore responsive behaviour
      // or grab them again next time
      const { content } = this.$refs;

      content.style.top = null;
      content.style.height = null;
      content.style.left = null;
      content.style.width = null;
    },
  },
};
</script>
