<template>
  <section>
    <div class="resources__top columns is-multiline">
      <div class="resources__intro column is-7">
        <h1 class="title is-1">{{ title }}</h1>
        <div class="rich-text" v-html="introHighlight"></div>
        <transition name="fade">
          <div v-if="!hasSearched" class="rich-text" v-html="introText"></div>
        </transition>
        <transition name="fade">
          <div v-if="!hasSearched" class="rich-text" v-html="introCta"></div>
        </transition>
      </div>

      <resource-search
        class="column is-12"
        label-id="resource-input"
        :topics="topics"
        :biasedBadges="biasedBadges"
        :hasSearched="hasSearched"
        :audiences="audiences"
        :communities="targetCommunities.value"
        @search="updateSearch"
        @toggle-refine="toggleRefine"
      ></resource-search>

      <transition name="fade">
        <resources-refine-filters class="column is-12 resources__refine-wrapper"
          :communities="targetCommunities"
          :biasedBadges="biasedBadges"
          v-if="showRefine && hasSearched"
          @toggle="toggleRefine"
          @search="updateSearch"
        ></resources-refine-filters>
      </transition>

      <transition name="fade">
        <div class="column is-12">
          <div
            v-show="showCommonSearches"
            class="resource-filters-module has-mb-24 resource-filters-module--commonsearch"
            :class="{'commonsearch-hidden': commonSearchCollapsed }"
          >
            <button
              class="resource-filters__toggle is-hidden-tablet"
              @click="toggleCommonSearch"
            >
              <div class="icon">
                <arrow-icon></arrow-icon>
              </div>
              <span class="title is-2">Common Searches</span>
            </button>
            <h2 class="title is-2 is-hidden-mobile">
              Common searches
            </h2>
            <ul class="resource-filters__wrapper">
              <li
                v-for="filter in featuredFilters"
                :key="filter.label"
                class="resource-filters__item"
              >
                <a
                  :href="filter.filter"
                  class="resource-filters__link"
                  @click.prevent="applyFeaturedFilter(filter.filter)"
                >{{ filter.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="introCriteria && !hasSearched"
          class="column is-4 is-offset-1 resources__criteria"
        >
          <info-box
            :content="introCriteria"
            :link="ratingLink"
          ></info-box>
        </div>
      </transition>
    </div>

    <div class="resources__results" v-if="hasSearched">
      <div class="resources__results-header is-flex">
        <div class="resources__count" >{{ resultsCountText }}</div>
        <div
          class="resources__sort is-flex"
          v-if="!orderDisabled"
        >
          Sort by:
          <div class="select is-grey-dark">
            <select name="sort" class="select" v-model="sortOrder" @change="updateSearch">
              <option value="website_title">A - Z</option>
              <option value="-website_title">Z - A</option>
              <option value="-score">Rating &darr;</option>
              <option value="score">Rating &uarr;</option>
            </select>
          </div>
        </div>
      </div>
      <transition-group name="fade">
        <resource
          v-for="resource in resources"
          :key="resource.id"
          :badges="resource.badges"
          :lastReviewed="resource.last_reviewed"
          :location="resource.location"
          :organisation="resource.organisation"
          :ratingLink="ratingLink"
          :breakdown="resource.score_breakdown"
          :score="resource.score"
          :summary="resource.summary"
          :tags="resource.tags"
          :websiteTitle="resource.website_title"
          :websiteUrl="resource.website_url"
          :slug="resource.slug"
          @search="updateSearch"
        ></resource>
      </transition-group>
    </div>

    <div class="resources__page_controls" v-if="hasSearched">
      <button v-if="prevDisabled" class="button resources__previous" disabled>
        <span class="icon icon--arrow">
          <arrow-icon></arrow-icon>
        </span>
        <span class="resources__button-text">Previous</span>
      </button>
      <a
        v-else
        :href="pageLink(-1)"
        class="button resources__previous"
        rel="prev"
        @click.prevent="getPage(-1, $event)"
      >
        <span class="icon icon--arrow">
          <arrow-icon></arrow-icon>
        </span>
        <span class="resources__button-text">Previous</span>
      </a>
      <p class="is-small resources__pages">Page {{ page + 1 }} of {{ pageCount }}</p>
      <button v-if="nextDisabled" class="button resources__next" disabled>
        <span class="resources__button-text">Next</span>
        <span class="icon icon--arrow">
          <arrow-icon></arrow-icon>
        </span>
      </button>
      <a
        v-else
        :href="pageLink(1)"
        class="button resources__next"
        rel="next"
        @click.prevent="getPage(1, $event)"
      >
        <span class="resources__button-text">Next</span>
        <span class="icon icon--arrow">
          <arrow-icon></arrow-icon>
        </span>
      </a>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { sendGaEvent } from '@/utils';
import InfoBox from '../InfoBox.vue';
import ResourceSearch from './ResourceSearch.vue';
import ResourcesRefineFilters from './ResourcesRefineFilters.vue';
import Resource from './Resource.vue';
import ArrowIcon from '../svg/ArrowIcon.vue';

const PAGE_TOTAL = 20;

export default {
  name: 'resource-index',
  components: {
    ResourceSearch,
    Resource,
    ArrowIcon,
    InfoBox,
    ResourcesRefineFilters,
  },
  props: {
    audiences: Object,
    featuredFilters: Array,
    introCriteria: String,
    introCta: String,
    introHighlight: String,
    introText: String,
    names: Array,
    ratingLink: String,
    targetCommunities: Object,
    title: String,
    topics: Object,
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalResources / PAGE_TOTAL);
    },
    resultsCountText() {
      return `${this.totalResources} result${this.totalResources > 1 ? 's' : ''}`;
    },
    prevDisabled() {
      return this.page === 0;
    },
    nextDisabled() {
      return this.page + 1 >= this.pageCount;
    },
    orderDisabled() {
      return this.totalResources <= 1;
    },
    showCommonSearches() {
      return this.featuredFilters.length > 0 && !this.hasSearched;
    },
    selectedTopic: {
      get() {
        return this.$store.state.selectedTopic;
      },
      set(value) {
        this.$store.commit('setSelectedTopic', value);
      },
    },
    selectedAudience: {
      get() {
        return this.$store.state.selectedAudience;
      },
      set(value) {
        this.$store.commit('setSelectedAudience', value);
      },
    },
    searchTerm: {
      get() {
        return this.$store.state.searchTerm;
      },
      set(value) {
        this.$store.commit('setSearchTerm', value);
      },
    },
    selectedBadges: {
      get() {
        return this.$store.state.selectedBadges;
      },
      set(value) {
        this.$store.commit('setSelectedBadges', value);
      },
    },
    selectedCommunities: {
      get() {
        return this.$store.state.selectedCommunities;
      },
      set(value) {
        this.$store.commit('setSelectedCommunities', value);
      },
    },
    sortOrder: {
      get() {
        return this.$store.state.sortOrder;
      },
      set(value) {
        this.$store.commit('setSortOrder', value);
      },
    },
    uniqueSlug: {
      get() {
        return this.$store.state.uniqueSlug;
      },
      set(value) {
        this.$store.commit('setUniqueSlug', value);
      },
    },
  },
  data() {
    return {
      page: 0,
      resources: [],
      totalResources: 0,
      showRefine: false,
      hasSearched: false,
      commonSearchCollapsed: true,
      biasedBadges: {
        is_australian: 'Australian',
        government_affiliation: 'Government Affiliation',
        religious_affiliation: 'Religious Affiliation',
        academic_affiliation: 'Academic',
        community_lead: 'Community-led',
      },
    };
  },
  mounted() {
    // Set event handler to call function when history changes
    window.onpopstate = this.updateFromUrl;
    this.updateFromUrl();
  },
  methods: {
    /**
     * Determine wether to show searched items or not
     */
    showSearched() {
      if (
        this.$store.state.uniqueSlug !== null
        || this.$store.state.searchTerm !== ''
        || this.$store.state.selectedTopic !== ''
        || this.$store.state.selectedAudience !== ''
      ) {
        this.hasSearched = true;
      } else {
        this.hasSearched = false;
      }
    },
    /**
     * Update filters from featured filter params
     */
    applyFeaturedFilter(filter) {
      const params = new URLSearchParams(filter);
      const topic = Number.parseInt(params.get('topic'), 10);
      if (topic) {
        this.selectedTopic = topic;
      }
      const audience = Number.parseInt(params.get('audience'), 10);
      if (audience) {
        this.selectedAudience = audience;
      }
      this.updateUrl();
      this.getData();
      this.showSearched();
    },
    /**
     * Contruct the query string for the API from selected filters/pages/slug
     */
    buildQueryString() {
      let queryString;
      if (this.uniqueSlug) {
        queryString = `slug=${this.uniqueSlug}`;
      } else {
        queryString = [];
        if (this.searchTerm) {
          queryString.push(`search=${this.searchTerm}`);
        }
        if (this.selectedTopic) {
          queryString.push(`topic=${this.selectedTopic}`);
        }
        if (this.selectedAudience) {
          queryString.push(`audience=${this.selectedAudience}`);
        }
        if (this.selectedBadges.length > 0) {
          this.selectedBadges.forEach((badge) => queryString.push(badge));
        }
        if (this.selectedCommunities.length > 0) {
          queryString.push(`communities=${this.selectedCommunities}`);
        }
        if (this.sortOrder) {
          queryString.push(`order=${this.sortOrder}`);
        }
        queryString.push(
          `limit=${PAGE_TOTAL}&offset=${this.page * PAGE_TOTAL}`,
        );
        queryString = queryString.join('&');
      }
      return `${process.env.VUE_APP_API_URL}resources/?${queryString}`;
    },
    /**
     * Return paginated resources, with applied filters
     */
    getData() {
      axios.get(this.buildQueryString())
        .then((response) => {
          this.resources = response.data.items;
          this.totalResources = response.data.meta.total_count;
          this.$nextTick().then(() => {
            this.uniqueSlug = null;
          });
        })
        .catch((error) => {
          console.error(`Error getting resources: ${error}`);
        });
    },
    /**
     * update page and reload data
     */
    getPage(increment, event) {
      this.page += increment;
      this.updateUrl();
      event.currentTarget.blur();
      window.scrollTo(0, 0);
      this.getData();
    },
    /**
     * Construct dummy page link for crawling
     */
    pageLink(increment) {
      let newPage;
      if (increment < 0) {
        newPage = this.page;
      } else {
        newPage = this.page + 2;
      }

      const queryString = [];

      if (this.searchTerm) {
        queryString.push(`search=${this.searchTerm}`);
      }
      if (this.selectedTopic) {
        queryString.push(`topic=${this.selectedTopic}`);
      }
      if (this.selectedAudience) {
        queryString.push(`audience=${this.selectedAudience}`);
      }
      if (this.selectedBadges.length > 0) {
        this.selectedBadges.forEach((badge) => queryString.push(badge));
      }
      if (this.selectedCommunities.length > 0) {
        queryString.push(`communities=${this.selectedCommunities}`);
      }
      if (newPage > 1) {
        queryString.push(`page=${this.page + 1 + increment}`);
      }
      if (queryString.length === 0) {
        return window.location.pathname;
      }
      return `?${queryString.join('&')}`;
    },
    /**
     * Reset page number
     */
    resetPage() {
      this.page = 0;
    },
    /**
     * Update data from URL params
     */
    updateFromUrl() {
      if (!window.location.search) return;

      const urlParams = new URLSearchParams(window.location.search);
      this.uniqueSlug = urlParams.get('slug');

      const search = urlParams.get('search');
      this.searchTerm = search || '';

      const topic = Number.parseInt(urlParams.get('topic'), 10);
      this.selectedTopic = topic || '';

      const audience = Number.parseInt(urlParams.get('audience'), 10);
      this.selectedAudience = audience || '';

      this.selectedCommunities = [];
      let communities = urlParams.getAll('communities');
      if (communities.length > 0) {
        communities = communities[0].split(',');
        communities.forEach((community) => {
          this.selectedCommunities.push(Number.parseInt(community, 10));
        });
      }
      this.selectedBadges = [];
      Object.keys(this.biasedBadges).forEach((badge) => {
        if (urlParams.has(badge)) {
          this.selectedBadges.push(badge);
        }
      });
      this.sortOrder = urlParams.get('order') || '-score';

      const urlPage = Number.parseInt(urlParams.get('page'), 10);
      if (urlPage && urlPage > 0) {
        this.page = urlPage - 1;
      } else {
        this.page = 0;
      }
      this.getData();
      this.showSearched();
    },
    /**
     * Update searchTerm when someone types in search bar and search again.
     */
    updateSearch() {
      this.resetPage();
      this.updateUrl();
      this.getData();
      this.showSearched();

      if (this.searchTerm) {
        // Don't send event with empty string
        sendGaEvent('Search', 'druginfo', this.searchTerm);
      }
    },
    /**
     * Update the url with the current state of data, avoiding ? if no params are set
     */
    updateUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      if (this.uniqueSlug) {
        urlParams.set('slug', this.uniqueSlug);
      } else {
        urlParams.delete('slug');
      }
      if (this.searchTerm) {
        urlParams.set('search', this.searchTerm);
      } else {
        urlParams.delete('search');
      }
      if (this.selectedTopic) {
        urlParams.set('topic', this.selectedTopic);
      } else {
        urlParams.delete('topic');
      }
      if (this.selectedAudience) {
        urlParams.set('audience', this.selectedAudience);
      } else {
        urlParams.delete('audience');
      }

      Object.keys(this.biasedBadges).forEach((badge) => {
        if (!this.selectedBadges.includes(badge)) {
          urlParams.delete(badge);
        } else {
          urlParams.set(badge, 'true');
        }
      });

      if (this.selectedCommunities.length > 0) {
        urlParams.set('communities', this.selectedCommunities);
      } else {
        urlParams.delete('communities');
      }

      if (this.page > 0) {
        urlParams.set('page', this.page + 1);
      } else {
        urlParams.delete('page');
      }

      if (this.sortOrder && this.sortOrder !== '-score') {
        urlParams.set('order', this.sortOrder);
      } else {
        urlParams.delete('order');
      }

      const queryString = urlParams.toString();
      if (queryString) {
        window.history.pushState({}, '', `?${queryString}`);
      } else {
        window.history.pushState({}, '', window.location.pathname);
      }
    },
    toggleRefine() {
      this.showRefine = !this.showRefine;
    },
    toggleCommonSearch() {
      this.commonSearchCollapsed = !this.commonSearchCollapsed;
    },
  },
};
</script>
