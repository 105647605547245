<template>
  <article class="service-result">
    <header class="service-result__header">
      <p class="service-result__name" data-test="service-result-name">{{ result.name }}</p>
      <h2 class="service-result__title title is-2">{{ result.site.name }}</h2>
      <p v-if="result.location && result.location.suburb" class="service-result__location">
        <span data-test="service-result-location">{{ locationText }}</span>
        <span
          v-if="nonlocalText"
          class="service-result__nonlocal"
          data-test="service-result-nonlocal"
          >{{ nonlocalText }} service</span
        >
        <span v-else-if="result.distanceText" class="has-text-weight-bold">{{
          result.distanceText
        }}</span>
      </p>
    </header>
    <div class="service-result__content">
      <p
        class="service-result__description"
        :class="{ expanded: expanded }"
        data-test="service-result__description"
      >
        {{ result.description }}
      </p>
      <!-- CTAs -->

      <div v-if="ctas.length" class="service-result__ctas">
        <a
          v-for="(cta, i) in ctas"
          :key="i"
          v-bind="cta.attrs"
          class="is-6 button service-result__cta-button"
        >
          <component :is="cta.icon" />
          <span class="service-result__cta-button-label">{{ cta.label }}</span>
        </a>
      </div>

      <!-- /CTAs -->
      <div
        class="service-result__control-content"
        :class="{ expanded: expanded }"
        data-test="service-result__control-content"
      >
        <h3 class="title is-3">Organisation Name</h3>
        <p data-test="service-result__organization">{{ result.site.organisation.name }}</p>
        <h3 class="title is-3">Address</h3>
        <p data-test="service-result__address">{{ address }}</p>
        <h3 class="title is-3">Phone</h3>
        <p
          v-for="(phone, index) in filteredPhones"
          :key="`${result.id}-phone-${index}`"
          class="service-result__phone"
          data-test="service-result__phones"
        >
          {{ phone.kind }}: <a :href="`tel:${phone.number}`">{{ phone.number }}</a>
        </p>
        <h3 v-if="result.emails.length > 0" class="title is-3">Email</h3>
        <p
          v-for="(address, index) in result.emails"
          :key="`${result.id}-address-${index}`"
          data-test="service-result__emails"
        >
          <a :href="`mailto:${address.email}`">{{ address.email }}</a>
        </p>
        <h3 class="title is-3">Website</h3>
        <p>
          <a :href="result.web" data-test="service-result__website">{{ result.web }}</a>
        </p>
        <h3 class="title is-3">Opening Hours</h3>
        <p
          v-for="(day, index) in result.opening_hours"
          :key="`${result.id}-day-${index}`"
          class="service-result__day"
          data-test="service-result__day"
        >
          <span>{{ day.day }}</span>
          <span>{{ formatOpeningString(day.open, day.close) }}</span>
        </p>
        <h3 class="title is-3">Accessibility</h3>
        <p data-test="service-result__access">{{ accessibilityText }}</p>
        <h3 class="title is-3">Cost</h3>
        <p data-test="service-result__cost">{{ result.cost }}</p>
        <h3 class="title is-3">Referral Type</h3>
        <p data-test="service-result__type">{{ result.referral_info }}</p>
        <h3 v-if="result.catchment" class="title is-3">Catchment Area</h3>
        <p data-test="service-result__catchment">{{ result.catchment }}</p>
        <h3 class="title is-3">Eligibility Type</h3>
        <p data-test="service-result__eligibility">{{ result.eligibility_info }}</p>
      </div>
    </div>
    <div v-if="hasReferrals" class="service-result__badges">
      <div class="badge-columns">
        <div v-if="referrals.self" class="badge-column">
          <service-badge text="Self Referral" data-test="badge-self"></service-badge>
        </div>
        <div v-if="referrals.medical" class="badge-column">
          <service-badge text="Medical Referral" data-test="badge-medical"></service-badge>
        </div>
        <div v-if="referrals.agency" class="badge-column">
          <service-badge text="Agency Referral" data-test="badge-agency"></service-badge>
        </div>
      </div>
    </div>
    <button class="service-result__control" @click="toggleExpanded()">
      <span>{{ buttonText }}</span>
      <span class="icon icon--arrow" :class="{ rotated: expanded }"><arrow-icon></arrow-icon></span>
    </button>
  </article>
</template>

<script>
import { sendGaEvent } from '@/utils';
import ServiceBadge from './ServiceBadge.vue';
import ArrowIcon from '../svg/ArrowIcon.vue';
import PhoneIcon from '../svg/PhoneIcon.vue';
import ExternalLink2Icon from '../svg/ExternalLink2Icon.vue';

const FORMAT_OPTIONS = { hour: 'numeric', minute: 'numeric' };

export default {
  name: 'ServiceResult',
  components: {
    ServiceBadge,
    ArrowIcon,
    PhoneIcon,
    ExternalLink2Icon,
  },
  props: {
    distance: {
      type: String,
      default: '',
    },
    forceClose: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    accessibilityText() {
      return this.result.accessibility === 'fullaccess'
        ? 'Full wheelchair access'
        : 'No wheelchair access';
    },
    address() {
      const base = this.result.location;
      return [
        base.flat_unit,
        base.level,
        base.building,
        [base.street_number, base.street_name, base.street_type].filter((val) => val).join(' '),
        [base.suburb, base.state, base.postcode].filter((val) => val).join(' '),
      ]
        .filter((val) => val)
        .join(', ');
    },
    buttonText() {
      return this.expanded ? 'Show less' : 'Show more';
    },
    filteredPhones() {
      const seenNumbers = [];
      return this.result.phones.filter((phone) => {
        if (!seenNumbers.includes(phone.number)) {
          seenNumbers.push(phone.number);
          return true;
        }
        return false;
      });
    },
    hasReferrals() {
      return Object.values(this.referrals).some((value) => value);
    },
    locationText() {
      if (!this.result.location.suburb) return '';

      const suburb = this.result.location.suburb
        .split(' ')
        .map((word) => word.slice(0, 1) + word.slice(1).toLowerCase())
        .join(' ');
      return `${suburb}, ${this.result.location.state}`;
    },
    nonlocalText() {
      if (this.result.location_type === 'National') return 'National';
      if (this.result.location_type === 'Statewide') return 'State wide';
      return '';
    },
    ctas() {
      const ctas = [];
      const phone = this.filteredPhones ? this.filteredPhones[0]?.number : null;
      const { web } = this.result;

      if (phone) {
        ctas.push({
          label: phone,
          icon: 'PhoneIcon',
          attrs: {
            href: `tel:${phone}`,
          },
        });
      }

      if (web) {
        ctas.push({
          label: 'Website',
          icon: 'ExternalLink2Icon',
          attrs: {
            href: web,
            target: '_blank',
          },
        });
      }

      return ctas;
    },
    referrals() {
      const referralInfo = this.result.referral_info.toLowerCase();
      return {
        self: referralInfo.includes('self'),
        medical: referralInfo.includes('medical'),
        agency: referralInfo.includes('agency'),
      };
    },
  },
  watch: {
    forceClose() {
      // React to changes in prop by closing accordion
      this.expanded = false;
    },
  },
  methods: {
    formatOpeningString(open, close) {
      if (open === '00:00:00' && close === '00:00:00') return 'All day';
      return `${this.parseServiceTime(open)} to ${this.parseServiceTime(close)}`;
    },
    parseServiceTime(time) {
      const [hours, mins] = time.split(':');
      const date = new Date(2021, 0, 1, hours, mins);
      return new Intl.DateTimeFormat('en-AU', FORMAT_OPTIONS).format(date).replace(' ', '');
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        // Send GA event on open
        sendGaEvent('ISS', 'Show more', this.result.site.name);
      }
    },
  },
};
</script>
