<template>
  <div class="drugs__search">
    <form>
      <label for="drugs-input">Search</label>
      <input class="drugs__input"
        id="drugs-input"
        type="text"
        aria-label="search for drug"
        placeholder="Search drug name, slang or effect."
        v-model="query"
        @focus="resetQuery"
      >
      <button class="drugs__submit" type="submit" title="submit search" disabled>
        <div class="icon icon--search"><search-icon></search-icon></div>
      </button>
      <p class="is-small has-text-grey-light" aria-live="assertive" role="alert">
        {{ resultText }}
      </p>
    </form>
    <div class="drugs__autocomplete" v-if="showAutocomplete">
      <div v-if="autoComplete.drug.length > 0">
        <h4>Drug names</h4>
        <ul>
          <li v-for="drug in autoComplete.drug" :key="drug.id">
            <a href="#" class="drugs__autocomplete-result" @click="selectFilter(drug.name)">
              {{ drug.name }}
            </a>
          </li>
        </ul>
      </div>
      <div v-if="autoComplete.effect.length > 0">
        <h4>Drug effects</h4>
        <ul>
          <li v-for="effect in autoComplete.effect" :key="effect.id">
            <a href="#" class="drugs__autocomplete-result" @click="selectFilter(effect.name)">
              {{ effect.name }}
            </a>
          </li>
        </ul>
      </div>
      <div v-if="autoComplete.streetName.length > 0">
        <h4>Drug slang</h4>
        <ul>
          <li v-for="streetName in autoComplete.streetName" :key="streetName.id">
            <a href="#" class="drugs__autocomplete-result" @click="selectFilter(streetName.name)">
              {{ streetName.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/components/svg/SearchIcon.vue';

export default {
  name: 'drug-search',
  components: { SearchIcon },
  props: ['resultCount', 'currentQuery', 'drugs', 'drugEffects', 'streetNames'],
  data() {
    return {
      query: '',
    };
  },
  computed: {
    /**
     * Displayed result text.
     */
    resultText() {
      let mainText = '';
      if (this.resultCount < 1) {
        mainText = 'No results, try another search';
      } else if (this.resultCount === 1) {
        mainText = 'Showing 1 result';
      } else {
        mainText = `Showing ${this.resultCount} results`;
      }
      let forText = '';
      if (this.currentQuery && this.resultCount > 0) {
        forText = ` for ${this.currentQuery}`;
      }
      return mainText + forText;
    },
    /**
     * get Autocomplete values
     */
    autoComplete() {
      const findMatch = (item) => item.name.match(new RegExp(this.query, 'i'));
      return {
        drug: this.drugs.filter(findMatch),
        effect: this.drugEffects.filter(findMatch),
        streetName: this.streetNames.filter(findMatch),
      };
    },
    /**
     * Determine whether to show the autocomplete prompt
     */
    showAutocomplete() {
      return this.query
        && this.query.length > 0
        && (this.autoComplete.drug.length > 0
        || this.autoComplete.effect.length > 0
        || this.autoComplete.streetName.length > 0);
    },
  },
  methods: {
    /**
     * emit selected autocomplete
     */
    selectFilter(payload) {
      this.$emit('autocomplete-selected', payload);
      this.query = '';
    },
    /**
     * set query to '' and update filter via event
     */
    resetQuery() {
      this.$emit('autocomplete-selected', '');
      this.query = '';
    },
  },
};
</script>
