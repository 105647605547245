<template>
  <div>
    <div class="resources__search" :class="{'searched': hasSearched}">
      <form class="resources__form is-flex-tablet" @submit.prevent="updateSearch(false)">
        <div class="resources__searchbar">
          <input
            :id="labelId"
            class="resources__input input is-grey-dark"
            type="text"
            aria-label="Search"
            placeholder="Search"
            v-model="searchTerm"
          >
          <div class="icon icon--search"><search-icon></search-icon></div>
        </div>
        <div class="resources__preposition is-hidden-mobile is-flex">
          in
        </div>
        <div class="resources__topic">
          <div class="select is-grey-dark is-filter">
            <select
              aria-label="Filter resources by topic"
              v-model="selectedTopic"
            >
              <option value="">{{ topics.name }} (optional) </option>
              <option
                v-for="topic in topics.value"
                :key="topic.id"
                :value="topic.id"
              >
                {{ topic.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="resources__preposition is-hidden-mobile is-flex">
          for
        </div>

        <div class="resources__audience">
          <div class="select is-grey-dark is-filter">

            <select
              aria-label="Filter resources by audience"
              v-model="selectedAudience"
            >
              <option value="">{{ audiences.name }} (optional)</option>
              <option
                v-for="audience in audiences.value"
                :key="audience.id"
                :value="audience.id"
              >
                {{ audience.name }}
              </option>
            </select>
          </div>
        </div>

        <button
          class="resources__submit button is-red is-large"
          type="submit"
          title="submit search"
          :disabled="!buttonActive"
        >
          Search
        </button>
      </form>

      <ul
        v-if="selectedBadges.length > 0 || selectedCommunities.length > 0"
        class="resources__refine-picks"
      >
        <li
          v-for="(filter, index) in selectedBadges"
          :key="filter"
          @click="removeRefineFilter('badges', index)"
          class="refine-pick"
        >
          {{ biasedBadges[filter] }}
          <span class="icon icon--close" aria-hidden="true"><close-icon></close-icon></span>
        </li>
        <li
          v-for="(filter, index) in selectedCommunities"
          :key="filter"
          class="refine-pick"
          @click="removeRefineFilter('communities', index)"
        >
          {{ getCommunityName(filter) }}
          <span class="icon icon--close" aria-hidden="true"><close-icon></close-icon></span>
        </li>
      </ul>

      <div class="resources__search-footer" v-if="hasSearched">
        <button class="resources__search-refinebutton is-uppercase" @click="toggleRefine()">
          Refine search
          <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
        </button>
        <button class="resources__search-reset" title="Reset filters" @click="updateSearch(true)">
          <span class="icon icon--reset"><reset-icon></reset-icon></span>
          <span>Reset Criteria</span>
        </button>
      </div>
      <div class="resources__search-background" :class="{'searched': hasSearched}"></div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/components/svg/SearchIcon.vue';
import CloseIcon from '@/components/svg/CloseIcon.vue';
import ResetIcon from '@/components/svg/ResetIcon.vue';
import ArrowIcon from '../svg/ArrowIcon.vue';

export default {
  name: 'resource-search',
  components: {
    SearchIcon,
    ResetIcon,
    ArrowIcon,
    CloseIcon,
  },
  props: {
    labelId: String,
    topics: Object,
    audiences: Object,
    showRefineSearch: Boolean,
    hasSearched: Boolean,
    communities: Array,
    biasedBadges: Object,
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.searchTerm;
      },
      set(value) {
        this.$store.commit('setSearchTerm', value);
      },
    },
    selectedTopic: {
      get() {
        return this.$store.state.selectedTopic;
      },
      set(value) {
        this.$store.commit('setSelectedTopic', value);
      },
    },
    selectedAudience: {
      get() {
        return this.$store.state.selectedAudience;
      },
      set(value) {
        this.$store.commit('setSelectedAudience', value);
      },
    },
    selectedBadges: {
      get() {
        return this.$store.state.selectedBadges;
      },
      set(value) {
        this.$store.commit('setSelectedBadges', value);
      },
    },
    selectedCommunities: {
      get() {
        return this.$store.state.selectedCommunities;
      },
      set(value) {
        this.$store.commit('setSelectedCommunities', value);
      },
    },
    sortOrder: {
      get() {
        return this.$store.state.sortOrder;
      },
      set(value) {
        this.$store.commit('setSortOrder', value);
      },
    },
    uniqueSlug: {
      get() {
        return this.$store.state.uniqueSlug;
      },
      set(value) {
        this.$store.commit('setUniqueSlug', value);
      },
    },
    buttonActive() {
      return this.searchTerm !== '' || this.selectedTopic !== '' || this.selectedAudience !== '';
    },
  },
  methods: {
    /**
     * get the name of the selected community filter
     */
    getCommunityName(id) {
      const name = this.communities.find((community) => community.id === id);
      return name.name;
    },
    /**
     * emit search
     * @param { Boolean } reset - If set, will clear the filters before emitting update
     */
    updateSearch(reset) {
      if (reset) {
        this.selectedTopic = '';
        this.selectedAudience = '';
        this.searchTerm = '';
        this.selectedCommunities = [];
        this.selectedBadges = [];
        this.sortOrder = '-score';
        this.uniqueSlug = null;
      }
      this.$emit('search');
      this.showAutocomplete = false;
    },
    /**
     * Remove refine filters
     * @param { String } filterType - Filter name in store
     * @param { String } key -  Key of filter to be removed
     */
    removeRefineFilter(filterType, key) {
      if (filterType === 'badges') {
        this.selectedBadges.splice(key, 1);
      } else {
        this.selectedCommunities.splice(key, 1);
      }
      this.updateSearch(false);
    },
    toggleRefine() {
      this.$emit('toggle-refine');
    },
  },
};
</script>
