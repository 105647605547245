<template>
  <div>
    <search-form @search-update="searchUpdate"></search-form>
    <p class="is-small search__count" v-if="searchTerm">
      Showing {{ totalCount }} results for {{ searchTerm }}
    </p>
    <div class="tablist" role="tablist" aria-label="search results">
      <div class="tablist-wrap">
        <button
          v-for="(values, key) in filteredResults"
          :key="key"
          class="tab"
          role="tab"
          :aria-selected="activeTab === key"
          @click="switchTab(key)"
        >
          {{ tabNames[key] }}
        </button>
      </div>
    </div>
    <div
      v-for="(values, key) in filteredResults"
      class="results"
      role="tabpanel"
      tabindex="0"
      :id="`tab-${key}`"
      :key="key"
      :hidden="activeTab !== key"
    >
      <template v-if="key === 'pages'">
        <template v-for="page in values">
          <insight-result
            v-if="page.meta.type === 'insights.InsightPage'"
            :key="page.id"
            :page-id="page.id"
          ></insight-result>
          <search-result
            v-else
            :key="page.id"
            :page-id="page.id"
            page-type="Related Page"
            :title="page.title"
          ></search-result>
        </template>
      </template>
      <template v-if="key === 'drugFacts'">
        <search-result
          v-for="page in values"
          :key="page.id"
          :title="page.title"
          page-type="Drug Fact Page"
          :url="page.url"
          :breadcrumbs="page.breadcrumbs"
          :description="page.short_description"
        ></search-result>
      </template>
      <div v-if="key === 'downloads'" class="downloads module">
        <download
          v-for="download in values"
          :key="download.id"
          :title="download.document_title"
          :url="download.document_url"
          :type="download.document_type"
          :size="download.document_size"
          :format="download.document_format"
        ></download>
      </div>
      <div class="module" v-if="results[key].total > results[key].results.length">
        <button class="button" @click="loadMore(key)">Load more {{ tabNames[key] }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Download from '@/components/standard/Download.vue';
import InsightResult from './InsightResult.vue';
import SearchForm from './SearchForm.vue';
import SearchResult from './SearchResult.vue';

export default {
  name: 'search',
  components: {
    SearchForm,
    SearchResult,
    InsightResult,
    Download,
  },
  data() {
    return {
      activeTab: 'drugFacts',
      results: {
        drugFacts: {
          total: 0,
          offset: 0,
          results: [],
        },
        pages: {
          total: 0,
          offset: 0,
          results: [],
        },
        downloads: {
          total: 0,
          offset: 0,
          results: [],
        },
      },
      searchTerm: '',
      tabNames: {
        drugFacts: 'Drug Facts',
        pages: 'Pages',
        insights: 'Insights',
        downloads: 'Downloads',
      },
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const searchTerm = urlParams.get('search');
    if (searchTerm) {
      this.searchTerm = searchTerm;
      this.updateSearch();
    }
  },
  computed: {
    filteredResults() {
      return Object.keys(this.results).reduce((filtered, key) => {
        if (this.results[key].results.length > 0) {
          filtered[key] = this.results[key].results; // eslint-disable-line no-param-reassign
        }
        return filtered;
      }, {});
    },
    totalCount() {
      return Object.keys(this.results).reduce((total, key) => total + this.results[key].total, 0);
    },
  },
  methods: {
    /**
     * Switch which tab is open
     */
    switchTab(tabName) {
      this.activeTab = tabName;
    },
    /**
     * update search term when form submitted.
     */
    searchUpdate(payload) {
      this.searchTerm = payload;
      this.updateSearch();
    },
    /**
     * Run search query
     */
    updateSearch() {
      this.searchDrugFactPages();
      this.searchStandardAndInsightPages();
      this.downloads();
    },
    /**
     * Load more results
     * @param {String} Key of resource to load more of
     */
    loadMore(key) {
      if (key === 'pages') {
        this.searchStandardAndInsightPages();
      } else if (key === 'downloads') {
        this.downloads();
      } else if (key === 'drugFacts') {
        this.searchDrugFactPages();
      }
    },
    /**
     * search standard pages.
     */
    searchStandardAndInsightPages() {
      let url = `${process.env.VUE_APP_API_URL}pages/?type=insights.InsightPage,standard.StandardPage`;
      url += `&search=${this.searchTerm}`;
      url += `&limit=5&offset=${this.results.pages.offset}`;

      axios.get(url)
        .then((response) => {
          this.results.pages.results = this.results.pages.results.concat(response.data.items);
          this.results.pages.total = response.data.meta.total_count;
          this.results.pages.offset += 5;
        })
        .catch((error) => {
          console.error(`Error getting standard/insight search results: ${error}`);
        });
    },
    /**
     * search drug fact pages.
     */
    searchDrugFactPages() {
      const facts = this.results.drugFacts;
      let url = `${process.env.VUE_APP_API_URL}pages/?type=drug_facts.DrugFactPage&fields=*`;
      url += `&search=${this.searchTerm}`;
      url += `&limit=5&offset=${facts.offset}`;

      axios.get(url)
        .then((response) => {
          facts.results = facts.results.concat(response.data.items);
          facts.total = response.data.meta.total_count;
          facts.offset += 5;
          if (facts.total < 1) {
            // Switch active tab to pages if no Drug Facts pages
            this.switchTab('pages');
          }
        })
        .catch((error) => {
          console.error(`Error getting drug fact data: ${error}`);
        });
    },
    /**
     * get downloads
     */
    downloads() {
      const { downloads } = this.results;
      let url = `${process.env.VUE_APP_API_URL}downloads/`;
      url += `?search=${this.searchTerm}`;
      url += `&limit=10&offset=${downloads.offset}`;

      axios.get(url)
        .then((response) => {
          downloads.results = downloads.results.concat(response.data.items);
          downloads.total = response.data.meta.total_count;
          downloads.offset += 10;
        })
        .catch((error) => {
          console.error(`Error getting downloads data: ${error}`);
        });
    },
  },
};
</script>
