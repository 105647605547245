<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.16666 5.8499C3.95007 5.8499 3.74234 5.93594 3.58919 6.0891C3.43604 6.24225 3.34999 6.44998 3.34999 6.66657V15.8332C3.34999 16.0498 3.43604 16.2576 3.58919 16.4107C3.74234 16.5639 3.95007 16.6499 4.16666 16.6499H13.3333C13.5499 16.6499 13.7576 16.5639 13.9108 16.4107C14.064 16.2576 14.15 16.0498 14.15 15.8332V10.8332C14.15 10.3638 14.5306 9.98324 15 9.98324C15.4694 9.98324 15.85 10.3638 15.85 10.8332V15.8332C15.85 16.5007 15.5848 17.1408 15.1129 17.6128C14.6409 18.0848 14.0008 18.3499 13.3333 18.3499H4.16666C3.4992 18.3499 2.85907 18.0848 2.38711 17.6128C1.91514 17.1408 1.64999 16.5007 1.64999 15.8332V6.66657C1.64999 5.99911 1.91514 5.35898 2.38711 4.88702C2.85908 4.41505 3.4992 4.1499 4.16666 4.1499H9.16666C9.6361 4.1499 10.0167 4.53046 10.0167 4.9999C10.0167 5.46934 9.6361 5.8499 9.16666 5.8499H4.16666Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M11.65 2.4999C11.65 2.03046 12.0306 1.6499 12.5 1.6499H17.5C17.9694 1.6499 18.35 2.03046 18.35 2.4999V7.4999C18.35 7.96934 17.9694 8.3499 17.5 8.3499C17.0306 8.3499 16.65 7.96934 16.65 7.4999V3.3499H12.5C12.0306 3.3499 11.65 2.96934 11.65 2.4999Z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M18.101 1.89886C18.433 2.23081 18.433 2.769 18.101 3.10094L8.93438 12.2676C8.60243 12.5996 8.06424 12.5996 7.7323 12.2676C7.40035 11.9357 7.40035 11.3975 7.7323 11.0655L16.899 1.89886C17.2309 1.56692 17.7691 1.56692 18.101 1.89886Z"/>
</svg>
</template>

<script>
export default {
  name: 'ExternalLink2Icon',
};
</script>

<style>

</style>
