<template>
  <div class="service__searchbox">
    <p class="title is-3 searchbox__service-type" data-test="searchbox-service-type">
      {{ serviceTypeLabel }}
    </p>
    <h3 class="title is-2 searchbox__title">{{ category }}</h3>
    <p class="searchbox__description" data-test="searchbox-description">{{ serviceDescription }}</p>
    <div class="searchbox__footer">
      <GoogleLocationSearch
        hide-label
        :label-id="formLabel"
        placeholder="Enter suburb"
        @update-location="setLocation"
      />
      <a v-bind="linkAttrs">Search</a>
    </div>
  </div>
</template>

<script>
import GoogleLocationSearch from '@/components/services/GoogleLocationSearch.vue';

export default {
  name: 'ServiceSearchbox',
  components: { GoogleLocationSearch },
  props: {
    category: String,
    description: String,
    newTab: {
      type: Boolean,
      default: false,
    },
    servicePageUrl: String,
    serviceType: String,
  },
  data() {
    return {
      location: '',
    };
  },
  computed: {
    formLabel() {
      const label = [this.category];
      if (this.serviceType) {
        label.push(this.serviceType);
      }
      return label.map((item) => item.toLowerCase()).join('-').replace(/ /g, '-');
    },
    /**
     * Set attributes for search link
     */
    linkAttrs() {
      const attrs = {
        href: this.searchLink,
        disabled: !this.location.toString(),
        class: 'button is-red',
      };
      if (this.newTab) {
        attrs.target = '_blank';
        attrs.rel = 'noopener noreferrer';
      }

      return attrs;
    },
    /**
     * Create user-facing link
     */
    searchLink() {
      const params = new URLSearchParams();
      params.set('service_category', this.category);
      if (this.serviceType) {
        params.set('service_type', this.serviceType);
      }
      params.set('area', this.location);

      return `${this.servicePageUrl}?${params.toString()}`;
    },
    /**
     * Service description
     */
    serviceDescription() {
      if (this.description) return this.description;
      const category = this.category ? ` ${this.category.toLowerCase()}` : '';
      return `Find${category} services available near you`;
    },
    /**
     * Return service type text
     */
    serviceTypeLabel() {
      return this.serviceType || 'All service types';
    },
  },
  methods: {
    /**
     * Set location
     */
    setLocation(value) {
      this.location = value;
    },
  },
};
</script>
