<template>
  <a
    class="card"
    :class="{ 'fade-in': visible, 'finished': noDelay }"
    :href="url"
    @transitionend="stopDelay()"
  >
    <img
      v-lazy="imageUrl"
      :alt="imageAlt"
      :width="imageWidth"
      :height="imageHeight"
      loading="lazy"
      class="card__img"
    />
    <div class="card__title" :class="{ 'has-category': category }">
      <p v-if="category" class="is-small">{{ category }}</p>
      <h3 class="title is-3">{{ title }}</h3>
    </div>
  </a>
</template>

<script>
export default {
  name: 'card',
  props: {
    title: String,
    url: String,
    imageUrl: String,
    imageWidth: String,
    imageHeight: String,
    imageAlt: String,
    category: String,
  },
  data() {
    return {
      visible: false,
      noDelay: false,
    };
  },
  mounted() {
    this.setupObserver();
  },
  methods: {
    /**
     * Setup an observer to fade-in the card as it scrolls into view
     */
    setupObserver() {
      const card = this.$el;

      if (!('IntersectionObserver' in window
      && 'IntersectionObserverEntry' in window
      && 'intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
        // If unsupported, just show the card and early exit
        this.visible = true;
        return;
      }

      const observer = new IntersectionObserver((entries) => {
        const event = entries[0];
        if (event.intersectionRatio >= 0.5) {
          // Show if visibility >= 50%
          this.visible = true;
        } else if (event.intersectionRatio <= 0.2) {
          // Hide if visibility <= 20%
          this.visible = false;
          this.noDelay = false;
        }
      }, { threshold: [0.2, 0.5] });

      observer.observe(card);
    },
    /**
     * Toggle a class to prevent the fade-in delay affecting the hover transition
     */
    stopDelay() {
      if (this.visible) {
        this.noDelay = true;
      }
    },
  },
};
</script>
