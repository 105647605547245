import axios from 'axios';

/**
 * Create a Google DistanceMatrixService instance.
 */
export const getDistanceService = () => {
  if (!window?.google?.maps) return null;
  return new window.google.maps.DistanceMatrixService();
};

/**
 * Manually calculate distance between two {lat,lon} points
 */
const calculateDistanceInMetres = (origin, dest) => {
  const { lat: lat1, lon: lon1 } = origin;
  const { lat: lat2, lon: lon2 } = dest;

  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  // prettier-ignore
  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
    + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres

  return Math.round(d);
};

/**
 * Get a human friendly string for a distance in metres
 */
const formatDistance = (metres) => {
  if (!metres || Number.isNaN(metres)) return '';

  if (metres < 1000) return `${metres}m`;

  const kms = Math.round(metres / 100) / 10;

  return `${kms}km`;
};

/**
 * Get Location distances by manual calculation
 */
const applyServiceDistances = async (origin, sourceItems) => {
  const items = sourceItems.map((service) => {
    // Bail early if there's nothing to do
    if (!service?.location?.point) return service;

    const { point } = service.location;
    const distanceValue = calculateDistanceInMetres(origin, point);
    const distanceText = formatDistance(distanceValue);
    // const order = index;

    return {
      ...service,
      distanceValue,
      distanceText,
    };
  });

  return items;
};

/**
 * Get the "driving" distances.
 * Note: we're not currently using this due to service limits (25 items) in the Google API
 */
export const applyServiceGoogleDistances = async (origin, sourceItems) => {
  const items = [...sourceItems];
  const distanceService = getDistanceService();

  // Bail early if there's nothing to do
  if (!distanceService || !items.length) return items;

  // Array of ("{lat},{lon}" | null)
  const destinations = items.map((service) => {
    // Store destinations with nulls, to map distances to when returned
    if (!service.location.point) return null;
    return `${service.location.point.lat},${service.location.point.lon}`;
  });

  // Distance API request args
  const request = {
    origins: [origin],
    destinations: destinations.filter((location) => location !== null),
    travelMode: 'DRIVING',
  };

  await distanceService.getDistanceMatrix(request, (response, status) => {
    if (status !== 'OK') {
      console.error('Error getting distance results');
      return;
    }

    const distances = response.rows[0].elements;

    destinations.map((dest, i) => {
      if (dest === null) return null;

      const distance = distances.shift();
      if (distance.status !== 'OK') return null;

      // Record the original order
      const order = i;

      const distanceText = distance.distance.text;
      const distanceValue = distance.distance.value;

      // Update each service object with its distance data
      items[i] = {
        ...items[i],
        distanceText,
        distanceValue,
        order,
      };

      return distanceText;
    });
  });

  return items;
};

/**
 * Info Exchange Search middleware
 * Facilitates distances and filters by location
 * @param {*} state The ServiceIndex object
 * @param {*} queryParams InfoExchange Search params
 */
export const submitSearch = async (state, queryParams, isFirstPage = true) => {
  //
  const maxResults = 100;

  const { perPage = 10 } = state;

  /**
   * We'll load only the first page of results initially,
   * then load all results in the background.
   */
  const appendResults = !isFirstPage;

  /**
   * Start with existing items, if appending results
   */
  const currentServices = appendResults ? state.services : [];
  const currentServiceIds = currentServices.map(({ id }) => id);

  if (isFirstPage) {
    // Quickly load the first page
    queryParams.set('limit', perPage);
    queryParams.set('offset', 0);
  } else {
    // Load the rest, assuming first page is loaded
    queryParams.set('limit', maxResults);
    // queryParams.set('offset', 0);
    queryParams.set('offset', perPage);
  }

  let errorMessage = '';
  let searchMeta = null;
  let rawServices = [];
  let hasSearch = false;

  const url = `${process.env.VUE_APP_API_URL}info-exchange/?${queryParams.toString()}`;

  await axios
    .get(url)
    .then((response) => {
      if (response.data.error_message) {
        errorMessage = response.data.error_message;
        searchMeta = null;
        rawServices = [];
      } else {
        errorMessage = '';
        searchMeta = response.data.meta;
        rawServices = response.data.objects;
      }
      hasSearch = true;
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(`Unable to contact InfoExchange: ${error}`);
      searchMeta = null;
      rawServices = [];
    });

  const locationPoint = searchMeta?.location;
  const locationState = searchMeta?.location?.state;

  // Filter out-of-state results
  const filteredResults = rawServices.filter((item) => {
    // Bail if exists
    if (!item?.id || currentServiceIds.includes(item.id)) return false;

    // Include "national" services
    const isNational = `${item.location_type}`.toLowerCase() === 'national';

    // Otherwise, limit results to in-state
    const sameState = item.location.state === locationState;

    return isNational || sameState;
  });

  // Update the new services with relative distances.
  const newServices = await applyServiceDistances(locationPoint, filteredResults);

  // Merge existing (if any) and new results
  const services = [...currentServices, ...newServices];

  const filteredMeta = {
    ...searchMeta,
    available_count: services.length,
  };

  state.errorMessage = errorMessage;
  state.searchMeta = filteredMeta;
  state.services = services;
  state.hasSearch = hasSearch;

  return true;
};

export default { submitSearch };
