<template>
  <section class="pathways">
    <transition name="fade-enter-delay">
      <div v-show="showNotification" class="pathways__crisis-notification">
        <div class="container">
          <alert-icon></alert-icon>
          <p>If you or someone you know is in a crisis, please call 000 immediately.</p>
        </div>
      </div>
    </transition>
    <div class="container pathways__quick-exit-container">
      <quick-exit class="pathways__quick-exit"></quick-exit>
    </div>
    <transition name="fade-enter-delay">
      <PathwaysResultsHeader v-show="showResults" />
    </transition>
    <hr v-show="!showResults" class="pathways__spacer" />
    <transition name="fade-enter-delay">
      <div v-show="showQuestionNavigation && !showResults" class="container pathways__progress-bar">
        <div class="columns is-centered">
          <div class="column is-10-tablet is-8-desktop">
            <PathwaysProgressBar></PathwaysProgressBar>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <component :is="currentComponent" :key="currentComponent"></component>
    </transition>
    <transition name="fade-enter-delay">
      <div v-show="showQuestionNavigation" class="pathways-pagination">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-10-tablet is-8-desktop pathways-pagination__button-container">
              <button class="button pathways__prev" @click="prevState()">
                <span class="icon icon--arrow">
                  <arrow-icon></arrow-icon>
                </span>
                <span>Previous</span>
              </button>
              <button
                v-show="!showResults"
                class="button pathways__next is-red"
                :disabled="nextDisabled"
                @click="nextState()"
              >
                <span>Next</span>
                <span class="icon icon--arrow">
                  <arrow-icon></arrow-icon>
                </span>
              </button>
              <button v-show="showResults" class="pathways__restart" @click="restart()">
                Start again
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import PathwaysProgressBar from '@/components/pathways/PathwaysProgressBar.vue';
import PathwaysResultsHeader from '@/components/pathways/PathwaysResultsHeader.vue';
import QuickExit from '@/components/pathways/QuickExit.vue';

import AlertIcon from '@/components/svg/AlertIcon.vue';
import ArrowIcon from '@/components/svg/ArrowIcon.vue';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('pathways');

export default {
  name: 'PathwaysIndex',
  components: {
    PathwaysQuestionBranch: () =>
      import('@/components/pathways/questions/PathwaysQuestionBranch.vue'),
    PathwaysQuestionDiscussion: () =>
      import('@/components/pathways/questions/PathwaysQuestionDiscussion.vue'),
    PathwaysQuestionGuidance: () =>
      import('@/components/pathways/questions/PathwaysQuestionGuidance.vue'),
    PathwaysQuestionLowerUsage: () =>
      import('@/components/pathways/questions/PathwaysQuestionLowerUsage.vue'),
    PathwaysQuestionDrugOptions: () =>
      import('@/components/pathways/questions/PathwaysQuestionDrugOptions.vue'),
    PathwaysQuestionOtherAge: () =>
      import('@/components/pathways/questions/PathwaysQuestionOtherAge.vue'),
    PathwaysQuestionOtherConcerned: () =>
      import('@/components/pathways/questions/PathwaysQuestionOtherConcerned.vue'),
    PathwaysQuestionOtherDrugControl: () =>
      import('@/components/pathways/questions/PathwaysQuestionOtherDrugControl.vue'),
    PathwaysQuestionOtherImpair: () =>
      import('@/components/pathways/questions/PathwaysQuestionOtherImpair.vue'),
    PathwaysQuestionOtherInjection: () =>
      import('@/components/pathways/questions/PathwaysQuestionOtherInjection.vue'),
    PathwaysQuestionOtherUsage: () =>
      import('@/components/pathways/questions/PathwaysQuestionOtherUsage.vue'),
    PathwaysQuestionResults: () =>
      import('@/components/pathways/questions/PathwaysQuestionResults.vue'),
    PathwaysQuestionSelfConcerned: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfConcerned.vue'),
    PathwaysQuestionSelfDesire: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfDesire.vue'),
    PathwaysQuestionSelfDrugControl: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfDrugControl.vue'),
    PathwaysQuestionSelfImpair: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfImpair.vue'),
    PathwaysQuestionSelfInjection: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfInjection.vue'),
    PathwaysQuestionSelfProblems: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfProblems.vue'),
    PathwaysQuestionSelfUsage: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfUsage.vue'),
    PathwaysQuestionSelfAge: () =>
      import('@/components/pathways/questions/PathwaysQuestionSelfAge.vue'),
    PathwaysProgressBar,
    PathwaysResultsHeader,
    QuickExit,
    AlertIcon,
    ArrowIcon,
  },
  props: {
    pageData: Object,
    serviceIndexUrl: String,
  },
  computed: {
    ...mapState({
      nextDisabled: (state) => state.nextDisabled,
      showResults: (state) => state.showResults,
    }),
    ...mapGetters(['currentComponent', 'showNotification', 'showQuestionNavigation']),
  },
  mounted() {
    this.savePageData(this.pageData);
    this.saveServiceUrl(this.serviceIndexUrl);
    this.checkUrlResults();
  },
  methods: {
    /**
     * Look for results in URL params, jump to results if present
     */
    checkUrlResults() {
      const params = new URLSearchParams(window.location.search);
      if (params.get('results') === 'true') {
        this.updateResultsFromURL(params);
      }
    },
    /**
     * Call store action and scroll to top
     */
    nextState() {
      this.next();
      window.scrollTo(0, 0);
    },
    /**
     * Call store action and scroll to top
     */
    prevState() {
      this.back();
      window.scrollTo(0, 0);
    },
    /**
     * Return to start of pathways
     */
    restart() {
      this.backToStart();
      window.scrollTo(0, 0);
    },
    ...mapActions({
      back: 'decrementIndex',
      backToStart: 'backToStart',
      next: 'incrementIndex',
      savePageData: 'savePageData',
      saveServiceUrl: 'saveServiceUrl',
      updateResultsFromURL: 'updateResultsFromURL',
    }),
  },
};
</script>
