<template>
  <form class="search__form" method="GET" action="/search">
    <input class="search__input" type="text" name="search"
      aria-label="search for drug" placeholder="Enter search term or keywords" v-model="search">
    <button class="search__submit" type="submit" title="submit search">
      <div class="icon icon--search" aria-hidden="true"><search-icon></search-icon></div>
    </button>
    <p class="is-small" aria-live="assertive" role="alert" v-if="resultCount > 0">
      Showing {{ resultCount }} results for "{{ search }}"
    </p>
  </form>
</template>

<script>
import SearchIcon from '@/components/svg/SearchIcon.vue';

export default {
  name: 'search-form',
  props: ['resultCount'],
  components: { SearchIcon },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    /**
     * Update search term in parent by emitting event.
     */
    updateSearchTerm() {
      this.$emit('search-update', this.search);
    },
  },
};
</script>
