<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <!-- eslint-disable-next-line max-len -->
    <path d="M21.625 24.375h-12.375c-0.563 0-1-0.5-1-1.063v-2.063c0-0.563 0.438-1 1-1s1.063 0.438 1.063 1v1.063h10.313v-1.063c0-0.563 0.438-1 1-1s1.063 0.438 1.063 1v2.063c0 0.563-0.5 1.063-1.063 1.063M13.938 28.875c-6.813-1.063-11.688-6.625-11.688-13.125 0-6.563 4.875-12.125 11.688-13.188 0.563-0.125 1-0.688 0.875-1.25s-0.688-0.938-1.25-0.875c-7.875 1.25-13.563 7.688-13.563 15.313 0 7.563 5.688 14 13.563 15.25 0.063 0 0.125 0 0.188 0 0.5 0 1-0.375 1.063-0.875 0.125-0.563-0.313-1.125-0.875-1.25M17.438 2.563c6.75 1.063 11.688 6.625 11.688 13.188 0 6.5-4.938 12.063-11.688 13.125-0.625 0.125-1 0.688-0.875 1.25 0.063 0.563 0.625 0.938 1.25 0.875 7.875-1.25 13.563-7.688 13.563-15.25 0-7.625-5.688-14.063-13.563-15.313-0.063 0-0.125 0-0.188 0-0.5 0-1 0.375-1.063 0.875-0.125 0.563 0.25 1.125 0.875 1.25M15.5 6.813c0.563 0 1 0.438 1 1v5.625c0 0.563-0.438 1-1 1s-1-0.438-1-1v-5.625c0-0.563 0.438-1 1-1M11.313 12.875c0.25 0 0.563 0.063 0.75 0.313l3.438 3.563 3.438-3.563c0.375-0.438 1.063-0.438 1.438-0.063 0.438 0.375 0.438 1.063 0 1.438l-4.188 4.375c-0.188 0.188-0.438 0.313-0.688 0.313v0c-0.313 0-0.563-0.125-0.75-0.313l-4.188-4.375c-0.375-0.375-0.375-1.063 0.063-1.438 0.188-0.188 0.438-0.25 0.688-0.25"></path>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
};
</script>
