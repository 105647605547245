<template>
  <div class="service-badge">
    <span class="service-badge__bg">
      <file-icon class="service-badge__icon"></file-icon>
    </span>
    <span>{{ text }}</span>
  </div>
</template>

<script>
import FileIcon from '../svg/FileIcon.vue';

export default {
  name: 'ServiceBadge',
  components: { FileIcon },
  props: {
    text: String,
  },
};
</script>
