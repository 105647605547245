<template>
  <svg class="star" :class="className" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path class="star__right" d="M12 .6l3.7 7.6L24 9.3l-6.1 5.8 1.5 8.3-7.4-4-7.4 4 1.5-8.3L0 9.3l8.3-1.2L12 .6z"/><path class="star__left" d="M12 19.4l-7.4 4 1.5-8.3L0 9.3l8.3-1.2L12 .6v18.8z"/></svg>
</template>

<script>
export default {
  name: 'StarIcon',
  props: {
    className: String,
  },
};
</script>
