<template>
  <!-- eslint-disable-next-line max-len -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path fill="#262626" fill-rule="evenodd" d="M11.716 0c2.579.037 4.762.953 6.548 2.749 1.787 1.796 2.7 3.976 2.736 6.542 0 .22-.11.33-.332.33h-8.952c-.258 0-.387-.11-.387-.33V.385c0-.257.129-.385.387-.385zm7.129 11.435v.11c0 2.565-.912 4.764-2.736 6.596-1.823 1.833-4.043 2.75-6.659 2.75-2.616 0-4.845-.917-6.687-2.75C.921 16.31 0 14.11 0 11.545 0 8.979.921 6.77 2.763 4.92 4.605 3.07 6.834 2.144 9.45 2.144h.111c.257 0 .386.128.386.385l-.055.055h.055v8.08c0 .257.11.386.332.386h8.179c.258 0 .387.128.387.385z"/></svg>
</template>

<script>
export default {
  name: 'WheelIcon',
};
</script>
