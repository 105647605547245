<template>
  <a
    href="https://www.google.com"
    class="button__quick-exit"
    @click.prevent="exit()"
  >
    <span>Quick exit (esc)</span>
    <close-icon></close-icon>
  </a>
</template>

<script>
import CloseIcon from '../svg/CloseIcon.vue';

export default {
  name: 'QuickExit',
  components: { CloseIcon },
  mounted() {
    window.addEventListener('keydown', this.checkEscape);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.checkEscape);
  },
  methods: {
    checkEscape(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        event.preventDefault();
        this.exit();
      }
    },
    clearAttributes(el) {
      while (el.attributes.length > 0) {
        el.removeAttribute(el.attributes[0].name);
      }
    },
    clearChildNodes(el) {
      while (el.firstChild) {
        el.removeChild(el.firstChild);
      }
    },
    exit() {
      // Remove DOM prior to moving in case of slow connections
      this.clearAttributes(document.querySelector('html'));

      const body = document.querySelector('body');
      this.clearChildNodes(body);
      this.clearAttributes(body);

      const head = document.querySelector('head');
      this.clearChildNodes(head);
      this.clearAttributes(head);
      const title = document.createElement('title');
      title.innerText = 'Google';
      head.appendChild(title);

      // Replace location without saving to history
      window.location.replace('https://www.google.com');
    },
  },
};
</script>
