<template>
  <!-- eslint-disable-next-line max-len -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><g fill="#FFF" fill-rule="evenodd"><circle cx="2.5" cy="2.5" r="2.5"/><circle cx="2.5" cy="10.5" r="2.5"/><circle cx="2.5" cy="18.5" r="2.5"/><circle cx="10.5" cy="2.5" r="2.5"/><circle cx="10.5" cy="10.5" r="2.5"/><circle cx="10.5" cy="18.5" r="2.5"/><circle cx="18.5" cy="2.5" r="2.5"/><circle cx="18.5" cy="10.5" r="2.5"/><circle cx="18.5" cy="18.5" r="2.5"/></g></svg>
</template>

<script>
export default {
  name: 'GridIcon',
};
</script>
