import Vue from 'vue';

import Accordion from '@/components/standard/Accordion.vue';
import Card from '@/components/Card.vue';
import DrugFactIndex from '@/components/drug_facts/DrugFactIndex.vue';
import DrugOverlay from '@/components/drug_facts/DrugOverlay.vue';
import DrugSearch from '@/components/drug_facts/DrugSearch.vue';
import InfoBox from '@/components/InfoBox.vue';
import InPageNav from '@/components/nav/InPageNav.vue';
import InsightIndex from '@/components/insights/InsightIndex.vue';
import LibraryCarousel from '@/components/standard/LibraryCarousel.vue';
import Navbar from '@/components/nav/Navbar.vue';
import MobileAccordion from '@/components/MobileAccordion.vue';
import NavbarMenuItem from '@/components/nav/NavbarMenuItem.vue';
import NavbarSubmenuItem from '@/components/nav/NavbarSubmenuItem.vue';
import PathwaysIndex from '@/components/pathways/PathwaysIndex.vue';
import PrintButton from '@/components/PrintButton.vue';
import ResourceIndex from '@/components/resources/ResourceIndex.vue';
import Search from '@/components/search/Search.vue';
import ServiceIndex from '@/components/services/ServiceIndex.vue';
import ServiceQuickSearch from '@/components/services/ServiceQuickSearch.vue';
import ServiceSearchbox from '@/components/services/ServiceSearchbox.vue';
import ServiceWrapper from '@/components/services/ServiceWrapper.vue';
import ShopProduct from '@/components/standard/ShopProduct.vue';
import Sidenav from '@/components/nav/Sidenav.vue';
import SidenavBlock from '@/components/nav/SidenavBlock.vue';
import SubscribeForm from '@/components/subscribe/SubscribeForm.vue';

import VueLazyload from 'vue-lazyload';
import App from './App.vue';
import store from './store';

Vue.use(VueLazyload, {
  lazyComponent: true,
});

Vue.config.productionTip = false;

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  store,
  components: {
    App,
    Accordion,
    Card,
    DrugFactIndex,
    DrugSearch,
    DrugOverlay,
    InfoBox,
    InPageNav,
    InsightIndex,
    LibraryCarousel,
    MobileAccordion,
    Navbar,
    NavbarMenuItem,
    NavbarSubmenuItem,
    PathwaysIndex,
    PrintButton,
    ResourceIndex,
    Search,
    ServiceIndex,
    ServiceQuickSearch,
    ServiceSearchbox,
    ServiceWrapper,
    ShopProduct,
    Sidenav,
    SidenavBlock,
    SubscribeForm,
  },
});

// Back to top

const setupBackToTop = () => {
  const backToTop = document.getElementsByClassName('js-back')[0];
  if (!backToTop) return;

  backToTop.addEventListener('click', (e) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
};

// Home

const offsetY = (e) => {
  let element = e;
  let top = 0;

  do {
    top += element.offsetTop || 0;
    element = element.offsetParent;
  } while (element);

  return top;
};

const setupHomeUnderlay = () => {
  const sections = document.getElementsByClassName('js-home_sections')[0];
  const underlay = document.getElementsByClassName('js-home_underlay')[0];
  if (!sections || !underlay) return;

  // Max opacity value
  const maxOpacity = 0.85;
  // Y position to start opacity change from 0
  const opacityStart = offsetY(underlay) - underlay.offsetHeight * 0.2;
  // Y position to finish opacity change at max opacity value
  const opacityFinish = offsetY(underlay) + underlay.offsetHeight * 0.2;
  // Canvas size of opacity change
  const canvas = opacityFinish - opacityStart;
  // Buffer for fast scroll to make sure opacity is able to reach closer to <= 0
  const buffer = -0.1;

  let scrolled = (window.scrollY - opacityStart) / canvas;
  let opacity = scrolled <= maxOpacity ? scrolled.toFixed(2) : maxOpacity;
  if (scrolled >= buffer) underlay.style.opacity = opacity;

  window.addEventListener('scroll', () => {
    scrolled = (window.scrollY - opacityStart) / canvas;
    if (scrolled >= buffer) {
      opacity = scrolled <= maxOpacity ? scrolled.toFixed(2) : maxOpacity;
      underlay.style.opacity = opacity;
    }
  });
};

/**
 * Fallback manual requestAnimFrame function
 */
const manualAnimationFrame = (callback) => {
  window.setTimeout(callback, 1000 / 60);
};

/**
 * Shim requestAnimFrame
 */
/* eslint-disable-next-line operator-linebreak */
window.requestAnimFrame = () =>
  /* eslint-disable-next-line implicit-arrow-linebreak, operator-linebreak */
  window.requestAnimationFrame ||
  /* eslint-disable-next-line operator-linebreak */
  window.webkitRequestAnimationFrame ||
  /* eslint-disable-next-line operator-linebreak */
  window.mozRequestAnimationFrame ||
  manualAnimationFrame;

/**
 * Custom eased scroll to function because Safari & IE don't support window.scrollTo options
 */
export default function scroll(y, duration) {
  const initialY = document.documentElement.scrollTop || document.body.scrollTop;
  const baseY = (initialY + y) * 0.5;
  const difference = initialY - baseY;
  const startTime = performance.now();

  function step() {
    let normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;

    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

const setupHomeHeroScroll = () => {
  const sections = document.getElementsByClassName('js-home_sections')[0];
  const button = document.getElementsByClassName('js-hero_scroll')[0];
  if (!sections || !button) return;

  let y = offsetY(sections);

  window.addEventListener('resize', () => {
    y = offsetY(sections);
  });

  button.addEventListener('click', (e) => {
    e.preventDefault();
    scroll(y, 600);
    button.blur();
  });
};

const setupHomeDrugsSlide = () => {
  let index = 0;
  const items = document.getElementsByClassName('js-home_drug');
  if (!items.length) return;

  items[index].classList.add('is-active');
  if (items.length < 2) return;

  setInterval(() => {
    const next = (index + 1) % items.length;
    items[index].classList.remove('is-active');
    setTimeout(() => items[next].classList.add('is-active'), 100);
    index = next;
  }, 5000);
};

// Library Search Module script embed
// https://adf.on.worldcat.org/
const setupAdfLibraryModule = () => {
  /* eslint-disable */
  const module = document.getElementById('discovery-search-box');
  if (!module) return;
  var d, w, tabList, h, form, input, urlBase, active, facets, v, r, rt, a, f, select, query;
  d = document;
  w = window;
  r = (function () {
    try {
      return w.self !== w.top;
    } catch (e) {
      return true;
    }
  })();
  rt = r ? '_blank' : '_self';
  a = d.getElementById('discovery-advanced-search');
  if (a) a.setAttribute('target', rt);
  tabList = d.querySelectorAll('#discovery-search-box span.material-tab');
  tabList = [].slice.call(tabList);
  h = function (e) {
    if (e.keyCode && e.keyCode !== 13) return;
    tabList.forEach(function (it) {
      it.className = 'material-tab';
    });
    this.className = 'material-tab active-tab';
  };
  tabList.forEach(function (tab) {
    tab.addEventListener('click', h);
    tab.addEventListener('keydown', h);
  });
  form = d.getElementById('discovery-search-form');
  input = d.getElementById('discovery-search');
  select = d.getElementById('discovery-search-select');
  urlBase =
    'https://adf.on.worldcat.org/external-search?queryString=#T#&databaseList=3652,1842,2375,638,3909,3384,2507,10658,2236,2897,2210,2478,2110,2513&clusterResults=on&groupVariantRecords=off&stickyFacetsChecked=off&changedFacet=stickyFacetsChecked&baseScope=wz%3A51813#F#';
  form.addEventListener('submit', function (e) {
    e.preventDefault();
    e.stopPropagation();
    f = '';
    active = d.querySelector('.material-tab.active-tab');
    if (active) {
      facets = JSON.parse(active.getAttribute('data-facets') || '[]');
      facets.forEach(function (facet) {
        console.log(facet);
        if (facet.key && facet.value && facet.value !== 'all') {
          f += '&' + facet.key + '=' + facet.value;
        }
      });
    }
    query = input.value;
    if (select) {
      var index = select.options[select.selectedIndex].value;

      if (index !== 'kw') query = select.options[select.selectedIndex].value + ':' + query;
    }
    w.open(urlBase.replace('#T#', encodeURIComponent(query)).replace('#F#', f), rt);
  });
};

const init = () => {
  // Back to Top
  setupBackToTop();

  // Modules
  setupAdfLibraryModule();

  // Home
  setupHomeUnderlay();
  setupHomeHeroScroll();
  setupHomeDrugsSlide();
};

window.addEventListener('load', init);
