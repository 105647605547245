<template>
  <ServiceResult :result="testService()" />
</template>

<script>
import ServiceResult from './ServiceResult.vue';

export default {
  name: 'ServiceWrapper',
  components: { ServiceResult },
  methods: {
    testService() {
      return {
        abn: '97643356590',
        accessibility: 'fullaccess',
        accreditation: [],
        adhc_eligible: false,
        age_groups: [
          'adult',
          'agedpersons',
          'middleagedadult',
          'preretirementage',
          'youngadult',
        ],
        also_known_as: [],
        assessment_criteria: '',
        availability: 'Telephone the Alcohol & Drug Information Service (ADIS) on 1300 131 340 between 8.30am and 10.00pm, seven days a week, for more information about this DASSA service or to make an appointment.',
        billing_method: '',
        cald_specific: false,
        capacity: {
          status: 'undef',
          status_text: '',
        },
        catchment: 'South Australia.',
        cost: 'Free or minimal fee may apply.',
        crisis: false,
        datasets: {
          ADF: {
            appears: true,
            external_id: '[new]',
          },
        },
        description: 'Provides assessment, counselling and inpatient medical detoxification in a supportive setting for people withdrawing from alcohol & other drugs.',
        details: '',
        eligibility_info: 'People withdrawing from alcohol & other drugs.',
        emails: [
          {
            comment: '',
            email: 'HealthDASSAGeneral@sa.gov.au',
          },
        ],
        endpoints: [],
        free_or_low_cost: true,
        funding_body: '',
        healthcare_card_holders: false,
        id: 762230,
        indigenous_classification: [
          'Mainstream',
        ],
        ineligibility_info: '',
        intake_info: '',
        intake_point: '',
        is_bulk_billing: false,
        languages: [],
        last_edited: '2021-05-16T23:10:38.458088',
        last_updated: '2021-03-25',
        lgbtiqa_plus_specific: false,
        location: {
          building: '',
          details: '',
          flat_unit: '',
          level: '',
          point: {
            lat: -34.94327390000001,
            lon: 138.6273346,
          },
          postcode: '5063',
          state: 'SA',
          street_name: 'Fullarton',
          street_number: '226',
          street_suffix: '',
          street_type: 'Road',
          suburb: 'GLENSIDE',
        },
        location_expiry: null,
        location_type: 'Permanent',
        name: 'Alcohol & Drug Withdrawal Management Service',
        ndis_approved: false,
        networks: [],
        now_open: {
          local_time: '2021-05-18T14:14:00+09:30',
          notes: '',
          now_open: true,
        },
        opening_hours: [
          {
            close: '22:00:00',
            day: 'Monday',
            note: '',
            open: '08:30:00',
          },
          {
            close: '22:00:00',
            day: 'Tuesday',
            note: '',
            open: '08:30:00',
          },
          {
            close: '22:00:00',
            day: 'Wednesday',
            note: '',
            open: '08:30:00',
          },
          {
            close: '22:00:00',
            day: 'Thursday',
            note: '',
            open: '08:30:00',
          },
          {
            close: '22:00:00',
            day: 'Friday',
            note: '',
            open: '08:30:00',
          },
          {
            close: '22:00:00',
            day: 'Saturday',
            note: '',
            open: '08:30:00',
          },
          {
            close: '22:00:00',
            day: 'Sunday',
            note: '',
            open: '08:30:00',
          },
          {
            close: '22:00:00',
            day: 'Public Holiday',
            note: '',
            open: '08:30:00',
          },
        ],
        parking_info: '',
        phones: [
          {
            comment: 'Local call cost',
            kind: 'phone',
            number: '1300 131 340',
          },
        ],
        postal_address: [
          {
            line1: '',
            line2: '',
            postcode: '',
            state: '',
            suburb: '',
          },
        ],
        promoted: false,
        public_transport_info: '',
        publicly_searchable: true,
        referral_info: 'Self or agency.',
        service_types: [
          'Alcohol & Other Drugs',
          'Alcohol, tobacco and other drugs',
          'Detoxification',
          'Detoxification from alcohol and other drugs',
          'Health Care Services',
          'Health Issue Specific',
          'Inpatient Withdrawal Management',
          'Mental health intervention services',
          'Therapeutic interventions',
          'Withdrawal Management',
        ],
        show_in_askizzy_health: false,
        site: {
          id: 762229,
          name: 'Drug & Alcohol Services South Australia (DASSA), Withdrawal Management, Glenside',
          organisation: {
            id: 4920561,
            name: 'Department for Health & Wellbeing',
          },
        },
        special_requirements: '',
        statewide: true,
        target_gender: 'u',
        type: 'service',
        web: 'http://www.sahealth.sa.gov.au/dassa',
      };
    },
  },
};
</script>

<style>

</style>
