<template>
  <div class="accordion module">
    <button
      class="accordion__toggle title is-2"
      :class="{ 'is-active': expanded }"
      :title="title"
      :aria-controls="label"
      :aria-expanded="ariaExpanded"
      @click="toggleContent"
    >
      {{ header }}
      <img
        class="accordion__arrow"
        src="@/assets/chevron-up.svg"
        width="61"
        height="21"
        role="presentation"
      />
    </button>
    <div
      class="accordion__content"
      :class="{ 'is-collapsed': !expanded }"
      :id="label"
      ref="content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accordion',
  props: ['header', 'slug'],
  data() {
    return {
      expanded: false,
      height: null,
    };
  },
  computed: {
    title() {
      return `Toggle accordion content for ${this.header}`;
    },
    label() {
      return `accordion-${this.slug}`;
    },
    ariaExpanded() {
      return this.expanded ? 'true' : 'false';
    },
  },
  mounted() {
    this.height = `${this.$refs.content.scrollHeight}px`;
  },
  methods: {
    /**
     * Shows/Hides the Accordion content
     */
    toggleContent() {
      if (this.expanded) {
        this.expanded = false;
        this.$refs.content.style.height = '';
      } else {
        this.expanded = true;
        this.$refs.content.style.height = this.height;
      }
    },
  },
};
</script>
