<template>
  <div>
    <div class="container drug-fact-container">
      <ul class="drug-tabs">
        <li
          class="drug-tab drug-tab--dark"
          :class="{'is-active' : activeTab === 'list'}"
          @click="activeTab = 'list'"
        >
          <span class="icon icon--grid" aria-hidden="true"><grid-icon></grid-icon></span>
          Drug List
        </li>
        <li
          class="drug-tab drug-tab--light"
          :class="{'is-active' : activeTab === 'wheel'}"
          @click="activeTab = 'wheel'"
        >
          <span class="icon icon--wheel" aria-hidden="true"><wheel-icon></wheel-icon></span>
          Drug Wheel
        </li>
      </ul>
    </div>
    <drug-list :title="title" v-show="activeTab === 'list'" :pageId="pageId"></drug-list>
    <drug-wheel :title="title" v-show="activeTab === 'wheel'" :pageId="pageId"></drug-wheel>
  </div>
</template>

<script>
import GridIcon from '@/components/svg/GridIcon.vue';
import WheelIcon from '@/components/svg/WheelIcon.vue';
import DrugList from './DrugList.vue';

export default {
  name: 'DrugFactIndex',
  components: {
    DrugList,
    DrugWheel: () => import('./DrugWheel.vue'),
    GridIcon,
    WheelIcon,
  },
  mounted() {
    if (window.location.hash && window.location.hash === '#wheel') {
      this.activeTab = 'wheel';
    }
  },
  props: ['title', 'pageId'],
  data() {
    return {
      activeTab: 'list',
    };
  },
};
</script>
