<template>
  <li
    class="nav_menu__item"
    :class="{ 'has-submenu': parent, 'is-focused': focused }"
    @focusin="setFocus"
    @focusout="removeFocus"
  >
    <a
      :href="url"
      class="nav_menu__link"
      :class="{ 'is-active': active }"
      :aria-current="ariaCurrent"
    >
      <span class="nav_menu__link_text">{{ title }}</span>
      <span v-if="icons" class="nav__icon icon--help"><map-icon></map-icon></span>
    </a>
    <template v-if="parent">
      <button
        class="nav_menu__cross"
        :class="{ 'is-active': submenuExpanded }"
        :title="buttonTitle"
        :aria-controls="ariaLabel"
        :aria-expanded="ariaExpanded"
        @click="toggleSubmenu"
      >
        <span class="nav_menu__cross--horizontal"></span>
        <span class="nav_menu__cross--vertical"></span>
      </button>
      <ul
        class="nav_submenu"
        :class="{ 'is-right': rightAligned, 'is-hidden-touch': !submenuExpanded }"
        :id="ariaLabel"
      >
        <slot></slot>
      </ul>
    </template>
  </li>
</template>

<script>
import MapIcon from '@/components/svg/MapIcon.vue';

export default {
  name: 'navbar-menu-item',
  components: { MapIcon },
  props: {
    active: Boolean,
    icons: Boolean,
    parent: Boolean,
    rightAligned: Boolean,
    slug: String,
    title: String,
    url: String,
  },
  data() {
    return {
      focused: false,
      submenuExpanded: false,
    };
  },
  computed: {
    ariaLabel() {
      return `navbar-submenu-${this.slug}`;
    },
    ariaCurrent() {
      return this.active ? 'page' : '';
    },
    ariaExpanded() {
      return this.submenuExpanded ? 'true' : 'false';
    },
    buttonTitle() {
      return `Toggle submenu for ${this.title}`;
    },
  },
  methods: {
    toggleSubmenu() {
      this.submenuExpanded = !this.submenuExpanded;
    },
    setFocus() {
      this.focused = true;
    },
    removeFocus() {
      this.focused = false;
    },
  },
};
</script>
