<template>
  <section class="service-quick-search">
    <h2 v-if="heading" class="title is-2">{{ heading }}</h2>

    <div v-if="text" v-html="text" class="rich-text" />

    <form @submit.prevent="newSearch()" class="service-quick-search__form">
      <h3 class="title is-3">Frequently used services</h3>

      <ul class="service-quick-search__frequent-options">
        <li>
          <input
            type="radio"
            id="family-counselling"
            v-model="serviceType"
            name="service_type"
            value="Family+and+Friends"
            checked
          >
          <label for="family-counselling">Family &amp; Friends counselling</label>
        </li>
        <li>
          <input
            type="radio"
            id="drug-counselling"
            v-model="serviceType"
            name="service_type"
            value="Drug+and+alcohol"
          >
          <label for="drug-counselling">Drug &amp; alcohol counselling</label>
        </li>

        <a href="/help-support/search/">More services</a>
      </ul>

      <div class="service-quick-search__second-row">
        <div class="service-quick-search__location">
          <GoogleLocationSearch
            label-id="hss-location-search"
            :override-value="urlLocation"
            placeholder="Enter suburb"
            @maps-loaded="getDistanceService()"
            @update-location="updateLocation($event)"
          />
        </div>

        <div class="service-quick-search__submit-column">
          <button type="submit" class="service-quick-search__submit-button">
            Search
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import GoogleLocationSearch from '@/components/services/GoogleLocationSearch.vue';

export default {
  name: 'ServiceQuickSearch',
  components: {
    GoogleLocationSearch,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serviceType: 'Family+and+Friends',
      location: null,
      urlLocation: null,
    };
  },
  methods: {
    newSearch() {
      const queryString = new URLSearchParams();
      queryString.set('area', this.location);
      queryString.set('service_category', 'Counselling');
      queryString.set('service_type', this.serviceType);
      window.location.href = `/help-support/search/?${decodeURIComponent(queryString.toString())}`;
    },
    /**
     * Create a DistanceMatrixService instance.
     */
    getDistanceService() {
      this.distanceService = new window.google.maps.DistanceMatrixService();
    },
    updateLocation(value) {
      this.location = value;
    },
  },
};
</script>
