/**
 * Debounces a function to only run after the provided delay
 * @param {Number} delay - The time to wait in ms
 * @returns {Function} - Returns Promise that resolves after debounce period
 */
const debounce = (delay) => {
  let timeout;
  return () => {
    clearTimeout(timeout);
    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(), delay);
    });
  };
};

/**
 * Send a Google Analytics event with the provided parameters
 * @param {String} category - The event category
 * @param {String} action - The action taken by the user
 * @param {String} label - Miscellaneous further information
 */
const sendGaEvent = (category, action, label) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: category,
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }
};

export { debounce, sendGaEvent };
