<template>
  <a :href="insight.url" class="tile is-child">
    <article class="insight-tile" :class="[tileClass, { 'is-loading': hidden }]">
      <img
        v-lazy="insight.card_image.url"
        :alt="insight.card_image.alt"
        :width="insight.card_image.width"
        :height="insight.card_image.height"
        loading="lazy"
        class="insight-tile__img"
      />
      <div class="insight-tile__content">
        <p class="insight-tile__category is-small">{{ insight.display_category }}</p>
        <h3 class="title is-3">{{ insight.title }}</h3>
        <p v-if="tilesize !== '1x1'" class="insight-tile__blurb" v-html="insight.blurb"></p>
        <p v-if="tilesize !== '1x1'" class="insight-tile__readmore">Read More</p>
      </div>
    </article>
  </a>
</template>

<script>
export default {
  name: 'insight',
  props: {
    delay: Number,
    insight: Object,
    tilesize: String,
  },
  data() {
    return {
      hidden: true,
    };
  },
  computed: {
    tileClass() {
      return `is-${this.tilesize}`;
    },
  },
  mounted() {
    setTimeout(() => {
      this.hidden = false;
    }, 180 * this.delay);
  },
};
</script>
