<template>
  <div :class="['nav__alert-bar', { 'nav__alert-bar--expanded': isExpanded }]">
    <div class="nav__alert-container">
      <span class="nav__alert-header"> Drug Alerts </span>
      <template v-if="isExpanded">
        <div class="nav__alert-list-expanded">
          <ul>
            <li
              v-for="(alert, index) in alerts"
              :key="index"
              class="nav__alert-list-expanded-padding"
            >
              <p class="nav__alert-text">{{ formatAlertDate(alert.date) }}, {{ alert.location }}</p>
              <br />
              <a :href="alert.link" target="_blank" class="nav__alert-link">
                {{ alert.title }}
              </a>
            </li>
          </ul>
          <span>
            <a href="https://theknow.org.au/alerts/" target="_blank" class="nav__alert-drug-link">
              Browse all drug alerts
            </a></span
          >
        </div>
        <button @click="toggleExpand" class="nav__alert-collapse-btn">
          <b>Less</b>
          <arrow-icon class="icon nav-icon-arrow-collapse"></arrow-icon>
        </button>
      </template>

      <template v-else>
        <span class="nav__alert-list">
          <span
            v-for="(alert, index) in displayedAlerts"
            :key="index"
            class="nav__alert-list-collapsed-text"
          >
            {{ formatAlertDate(alert.date) }}, {{ alert.location }} ·
            <a :href="alert.link" target="_blank" class="nav__alert-link"
              >{{ alert.short_title }}
            </a>
          </span>
        </span>
        <button @click="toggleExpand" class="nav__alert-expand-button">
          <b> + more </b>
          <arrow-icon class="icon nav-icon-arrow"></arrow-icon>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/components/svg/ArrowIcon.vue';

export default {
  name: 'AlertBar',
  components: {
    ArrowIcon,
  },
  props: {
    initialVisibleCount: {
      type: Number,
      default: 2,
    },
    alerts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    displayedAlerts() {
      return this.alerts.slice(0, this.initialVisibleCount);
    },
  },
  methods: {
    formatAlertDate(dateString) {
      const options = { day: '2-digit', month: 'short' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
