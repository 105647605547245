<template>
  <div class="in-page-nav-container" :class="[headerOpen]">
    <div class="in-page-nav__header">
      <span>On this page</span>
    </div>
    <ul class="in-page-nav">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InPageNav',
  computed: {
    headerOpen() {
      return {
        'header-active': !this.headerHidden,
      };
    },
    headerHidden() {
      return this.$store.state.navbarHidden;
    },
  },
  watch: {
    headerHidden(newValue) {
      window.isHeaderHidden = newValue;
    },
  },
  mounted() {
    window.isHeaderHidden = this.headerHidden;
  },
};
</script>
