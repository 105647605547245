<template>
  <article class="result">
    <p class="is-small is-pulled-right is-hidden-mobile">{{ pageType }}</p>
    <a :href="pageData.url" class="result__title">
      <h3 class="title is-3-to-2">{{ title }}</h3>
    </a>
    <ul class="result__breadcrumbs">
      <li v-for="breadcrumb in pageData.breadcrumbs" :key="breadcrumb.id" class="result__crumb">
        <a :href="breadcrumb.url">{{ breadcrumb.title }}&nbsp;</a>
      </li>
    </ul>
    <p v-html="pageData.description"></p>
  </article>
</template>

<script>
import axios from 'axios';

export default {
  name: 'search-result',
  props: {
    breadcrumbs: Array,
    description: String,
    pageId: Number,
    pageType: String,
    title: String,
    url: String,
  },
  data() {
    return {
      pageData: {
        breadcrumbs: this.breadcrumbs,
        description: this.description,
        url: this.url,
      },
    };
  },
  mounted() {
    if (this.pageId) {
      this.getPageData();
    }
  },
  methods: {
    getPageData() {
      axios.get(`${process.env.VUE_APP_API_URL}pages/${this.pageId}/?fields=_,breadcrumbs,short_description,url`)
        .then((response) => {
          this.pageData.breadcrumbs = response.data.breadcrumbs;
          this.pageData.description = response.data.short_description;
          this.pageData.url = response.data.url;
        })
        .catch((error) => {
          console.error(`Error getting page data: ${error}`);
        });
    },
  },
};
</script>
