<template>
  <div class="pathways__results-header" :class="headerColor">
    <div class="container">
      <div class="columns is-centered is-not-vertical">
        <div class="column is-10-tablet is-8-desktop">
          <p v-if="risk" class="title is-3" :class="{ 'has-text-white': isHighRisk }">Results</p>
          <h2 class="title is-1" :class="{ 'has-text-white': isHighRisk }">
            {{ headerText }}
          </h2>
          <p v-if="risk === 'high'" class="mb-16">
            Your current pattern of substance use is considered high risk and is likely to be
            affecting your health, relationships, work, school, finances or other life situations.
          </p>
          <p v-else-if="risk === 'medium'" class="mb-16">
            Your current pattern of substance use is considered medium risk and may be affecting
            your health, relationships, work, school, finances or other life situations.
          </p>
          <p v-else-if="risk === 'low'" class="mb-16">
            Your current pattern of substance use is considered low risk and is less likely to
            result in health problems or other issues that could harm yourself or others.
          </p>
          <p v-else class="mb-16">
            Based on your answers, we’ve pulled together relevant resources and services to help you
            find support for yourself and your loved one.
          </p>
          <button
            class="button is-small is-rounded button__pathway-results-buttons"
            :class="{ 'button__pathway-results-buttons--white': isHighRisk }"
            @click="displayServices()"
            id="pathway-display-services-button"
          >
            <user-icon width="24" height="24"></user-icon>
            <span> Find Support Services</span>
          </button>
          <button
            class="button is-small is-rounded button__pathway-results-buttons"
            :class="{ 'button__pathway-results-buttons--white': isHighRisk }"
            @click="displayResources()"
            id="pathway-display-resources-button"
          >
            <document-icon width="24" height="24"></document-icon>
            <span> View helpful information</span>
          </button>
          <p class="is-small mt-24"><strong>Need to return to your results later?</strong></p>
          <p class="is-small mb-8">Copy the link below and save it somewhere safe.</p>
          <CopyLink :is-white="isHighRisk" :url="resultsUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import CopyLink from '@/components/CopyLink.vue';
import DocumentIcon from '@/components/svg/DocumentIcon.vue';
import UserIcon from '@/components/svg/UserIcon.vue';

const { mapState, mapActions } = createNamespacedHelpers('pathways');

export default {
  name: 'PathwaysResultsHeader',
  components: {
    CopyLink,
    DocumentIcon,
    UserIcon,
  },
  computed: {
    /**
     * Apply different colour based on risk result
     */
    headerColor() {
      return {
        'pathways__results-header--green': this.risk === 'low',
        'pathways__results-header--yellow': this.risk === 'medium',
        'pathways__results-header--red has-text-white': this.isHighRisk,
      };
    },
    /**
     * Header heading
     */
    headerText() {
      if (this.risk) {
        const capWord = this.risk.slice(0, 1).toUpperCase() + this.risk.slice(1);
        return `${capWord} risk`;
      }
      return 'Results';
    },
    /**
     * Return true if risk is high
     */
    isHighRisk() {
      return this.risk === 'high';
    },
    resultsUrl() {
      const params = new URLSearchParams();
      params.set('results', true);
      if (this.risk) {
        const riskMap = { low: 1, medium: 2, high: 3 };
        params.set('level', riskMap[this.risk]);
      }
      if (this.selectedDrugs) {
        params.set('drugs', this.selectedDrugs.join(','));
      }
      if (this.results.youthServices) {
        params.set('youth', true);
      }
      if (this.results.injectionServices) {
        params.set('injection', true);
      }
      if (this.results.informationServices) {
        params.set('info', true);
      }
      if (this.results.counsellingServices) {
        params.set('counsel', true);
      }
      if (this.results.rehabilitationServices) {
        params.set('rehab', true);
      }
      if (this.results.discussionServices) {
        params.set('discuss', true);
      }
      if (this.results.healthAdviceServices) {
        params.set('advice', true);
      }
      return `${window.location.href}?${params.toString()}`;
    },
    ...mapState({
      results: (state) => state.results,
      risk: (state) => state.risk,
      selectedDrugs: (state) => state.responses.drugSelection,
      showResourcesResults: (state) => state.showResourcesResults,
    }),
  },
  methods: {
    /**
     * Call store action and scroll to top
     */
    displayResources() {
      this.showResources();
      const section = document.getElementById('pathways-results-resources');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    /**
     * Call store action and scroll to top
     */
    displayServices() {
      this.showServices();
      const section = document.getElementById('pathways-results-services');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    ...mapActions(['showResources', 'showServices']),
  },
};
</script>
