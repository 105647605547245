<template>
  <div class="module module--library-carousel">
    <h3 class="title is-2">{{ header }}</h3>

    <flickity ref="flickity" :options="flickityOptions">
      <div v-for="book in books" :key="book.id" class="carousel-cell">
        <a :href="book.url" class="is-block">
          <img
            :src="book.image.url"
            :alt="book.image.alt"
            class="library-carousel__image"
            loading="lazy"
          >
        </a>
        <h4 class="title is-3">{{ book.title }}</h4>
      </div>
    </flickity>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';

const BREAKPOINT = 952;

export default {
  name: 'LibraryCarousel',
  components: { Flickity },
  props: {
    books: Array, // of Objects
    header: String,
  },
  data() {
    return {
      isDesktop: false,
      showArrows: false,
    };
  },
  computed: {
    flickityOptions() {
      return {
        cellAlign: 'left',
        contain: true,
        freeScroll: !this.isDesktop,
        groupCells: true,
        pageDots: false,
        prevNextButtons: this.isDesktop && this.showArrows,
      };
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize);
    this.$nextTick().then(this.resize);
  },
  methods: {
    resize() {
      if (!this.$refs.flickity) return;
      const atDesktop = window.innerWidth >= BREAKPOINT;

      if (atDesktop !== this.isDesktop) {
        this.isDesktop = atDesktop;
        if (this.isDesktop) {
          this.showArrows = this.$refs.flickity.slides().length > 1;
        }
        this.$nextTick().then(this.$refs.flickity.rerender);
      }
    },
  },
};
</script>
