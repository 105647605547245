<template>
  <button
    class="button is-small is-rounded button__copy-link"
    :class="{ 'button__copy-link--white': isWhite }"
    @click="copyLink"
  >
    <component :is="icon" width="16" height="16"></component>
    <span>{{ buttonText }}</span>
  </button>
</template>

<script>
import CheckIcon from '@/components/svg/CheckIcon.vue';
import LinkIcon from '@/components/svg/LinkIcon.vue';

export default {
  name: 'CopyLink',
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: () => window.location.href,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    buttonText() {
      return this.copied ? 'Copied' : 'Copy Link';
    },
    icon() {
      return this.copied ? CheckIcon : LinkIcon;
    },
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.url);
      this.copied = true;
      setTimeout(() => { this.copied = false; }, 3000);
    },
  },
};
</script>
