<template>
  <div class="insight-filters">
    <div class="insight-featured is-hidden-mobile">
      <ul class="insight-featured__filters">
        <li>
          <a
            href="#"
            class="insights__button button is-grey"
            @click.prevent="updateFilters(null)"
          >
            All Insights
          </a>
        </li>
        <li v-for="category in featuredCategories" :key="category.id">
          <a
            href="#"
            class="insights__button button is-grey"
            @click.prevent="updateFilters(category.id)"
          >
            {{ category.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="insight-select select is-small is-grey-dark has-large-arrow">
      <select name="category" @change="updateFilters(selectedCategory)" v-model="selectedCategory">
        <option disabled selected value>More news categories</option>
        <option
          v-for="category in categories"
          :key="category.id"
          :value="category.id"
        >
          {{ category.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'insight-filters',
  props: {
    categories: Array,
    featuredCategories: Array,
  },
  data() {
    return {
      selectedCategory: '',
    };
  },
  methods: {
    updateFilters(categoryId) {
      this.$emit('category-update', categoryId);
    },
  },
};
</script>
