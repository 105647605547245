<template>
  <div>
    <section class="services__form-wrap services__form">
      <div class="container">
        <h2 class="title is-2" v-if="filtersHeading">{{ filtersHeading }}</h2>
        <form class="columns is-multiline" @submit.prevent="newSearch()">
          <div class="column is-4">
            <label for="service-category">Service category<sup>*</sup></label>
            <div class="select">
              <select
                required
                id="service-category"
                name="service-category"
                v-model="selectedServiceCategory"
                @change="resetType"
              >
                <option value="">Select service category</option>
                <option v-for="option in categories" :key="option.label" :value="option">{{
                  option.label
                }}</option>
              </select>
            </div>
          </div>
          <div class="column is-4">
            <label>Service type</label>
            <div class="select" :class="{ disabled: disableType }">
              <select :disabled="disableType" name="service-type" v-model="selectedServiceType">
                <option value="">Select service type</option>
                <option v-for="option in serviceTypes" :key="option.label" :value="option">{{
                  option.label
                }}</option>
              </select>
            </div>
          </div>
          <div class="services__search column is-4">
            <label for="services-search">Keyword</label>
            <div class="is-relative">
              <input
                id="services-search"
                name="keyword"
                type="text"
                aria-label="Refine by keyword"
                placeholder="Refine by keyword"
                class="input is-grey-dark"
                v-model="keyword"
              />
              <div class="icon icon--search"><search-icon></search-icon></div>
            </div>
          </div>
          <div class="column is-4">
            <GoogleLocationSearch
              label-id="hss-location-search"
              :override-value="urlLocation"
              placeholder="Enter suburb"
              @maps-loaded="getDistanceService()"
              @update-location="updateLocation($event)"
            />
          </div>
          <div class="column is-8 services__submit-column">
            <button
              type="submit"
              :disabled="loading"
              class="column is-3 button is-red services__submit"
            >
              <span v-if="loading">Searching...</span>
              <span v-if="!loading">Search</span>
            </button>
            <button class="services__reset" @click="resetForm()">
              <span class="icon icon--reset"><reset-icon></reset-icon></span>
              <span>Reset Criteria</span>
            </button>
          </div>
        </form>
      </div>
    </section>
    <section v-if="hasSearch" class="container services__results">
      <div class="columns">
        <div class="column is-4 services__checkbox-column">
          <button
            class="services__checkbox-button is-hidden-tablet"
            @click="setCheckboxes(!expandCheckboxes)"
          >
            <span>Refine results</span>
            <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
          </button>
          <h2 class="title is-2 services__checkbox-heading is-hidden-mobile">Refine results</h2>
          <div class="services__checkboxes" :class="{ 'is-hidden-mobile': !expandCheckboxes }">
            <label v-for="checkbox in checkboxes" :key="checkbox.id" class="services__checkbox">
              <input
                type="checkbox"
                :id="checkbox.id"
                :name="checkbox.name"
                :value="checkbox.value"
                :disabled="loading"
                v-model="servicesCheckboxes"
                @change="newFilter()"
              />
              <span>{{ checkbox.displayName }}</span>
              <span class="icon icon--check"></span>
            </label>
            <hr class="services__checkbox-hr is-hidden-tablet" />
            <button
              type="button"
              class="button is-red services__submit is-hidden-tablet"
              @click="setCheckboxes(false)"
            >
              View results
            </button>
          </div>
        </div>
        <div class="column is-8" ref="results">
          <div class="services__form">
            <div class="services__form-meta-row">
              <p class="title is-3">
                {{ resultsText }}
              </p>

              <div class="services__form-sort">
                <label>Sort by</label>
                <div class="select">
                  <select name="sortby" v-model="selectedSortBy">
                    <option
                      v-for="option in sortOptions"
                      :key="option.label"
                      :value="option.value"
                      >{{ option.label }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
          </div>

          <template v-if="services.length > 0">
            <ul>
              <li v-for="(featuredResult, i) in filteredFeaturedResults" :key="i">
                <featured-service-result
                  :name="featuredResult.service_name"
                  :title="featuredResult.title"
                  :category="featuredResult.service_category"
                  :description="featuredResult.description"
                  :phone-number="featuredResult.phone_number"
                  :url="featuredResult.website_url"
                />
              </li>
              <li v-for="service in orderedResults" :key="service.id">
                <service-result :result="service" :force-close="forceClose"></service-result>
              </li>
            </ul>
            <div v-if="services.length > 0" class="services__page-controls">
              <button v-if="disablePrev" class="button services__previous" disabled>
                <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
                <span class="services__button-text">Previous</span>
              </button>
              <a
                v-else
                :href="pageLink(-1)"
                class="button services__previous"
                rel="prev"
                @click.prevent="getPage(-1, $event)"
              >
                <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
                <span class="services__button-text">Previous</span>
              </a>
              <p class="is-small services__pages">{{ paginationText }}</p>
              <button v-if="disableNext" class="button services__next" disabled>
                <span class="services__button-text">Next</span>
                <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
              </button>
              <a
                v-else
                :href="pageLink(1)"
                class="button services__next"
                rel="next"
                @click.prevent="getPage(1, $event)"
              >
                <span class="services__button-text">Next</span>
                <span class="icon icon--arrow"><arrow-icon></arrow-icon></span>
              </a>
            </div>
          </template>
          <div v-else class="pattern-block--service">
            <h3 class="service-block__title title is-2">{{ noResultsHeader }}</h3>
            <p>{{ noResultsBody }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios';

import GoogleLocationSearch from '@/components/services/GoogleLocationSearch.vue';
import ServiceResult from '@/components/services/ServiceResult.vue';
import ArrowIcon from '@/components/svg/ArrowIcon.vue';
import ResetIcon from '@/components/svg/ResetIcon.vue';
import SearchIcon from '@/components/svg/SearchIcon.vue';
import FeaturedServiceResult from '@/components/services/FeaturedServiceResult.vue';
import { submitSearch } from './searchHelpers';

const CHECKBOX_DATA = [
  {
    id: 'check-ethnicity',
    name: 'ethnicity',
    value: 'ethnicity',
    displayName: 'Aboriginal Torres Strait Islanders',
  },
  {
    id: 'check-diverse',
    name: 'diverse',
    value: 'diverse',
    displayName: 'Culturally and linguistically diverse',
  },
  {
    id: 'check-lgbtiq',
    name: 'lgbtiq',
    value: 'lgbtiq',
    displayName: 'LGBTIQ+',
  },
  {
    id: 'check-youth',
    name: 'youth',
    value: 'youth',
    displayName: 'Youth',
  },
  {
    id: 'check-wheelchair',
    name: 'wheelchair-accessibility',
    value: 'wheelchair',
    displayName: 'Wheelchair Accessibility',
  },
];

const SORT_OPTIONS = [
  {
    value: 'relevance',
    label: 'Relevance',
  },
  {
    value: 'distance',
    label: 'Distance: Nearest first',
  },
];

const DEFAULT_SORT = SORT_OPTIONS[0].value;

export default {
  name: 'ServiceIndex',
  components: {
    GoogleLocationSearch,
    ServiceResult,
    ArrowIcon,
    ResetIcon,
    SearchIcon,
    FeaturedServiceResult,
  },
  props: {
    categories: Array,
    filtersHeading: String,
    featuredSupportServices: Array,
  },
  data() {
    return {
      loading: false,
      distanceService: null,
      errorMessage: '',
      expandCheckboxes: false,
      forceClose: false,
      hasSearch: false,
      keyword: '',
      location: null,
      locationDistances: null,
      page: 1,
      perPage: 10,
      searchMeta: null,
      selectedServiceCategory: '',
      selectedServiceType: '',
      selectedSortBy: DEFAULT_SORT,
      services: [],
      orderedResults: [],
      filteredFeaturedResults: [],
      servicesCheckboxes: [],
      urlLocation: null,
    };
  },
  mounted() {
    this.updateFromUrl();
  },
  computed: {
    paginationText() {
      const isLoading = this.loading;

      return isLoading ? '' : `Page ${this.page} of ${this.pageCount}`;
    },
    resultsText() {
      const hasResults = !!this.totalResults;
      const isLoading = this.loading;

      if (hasResults) {
        return `${this.totalResults} results${isLoading ? ', searching...' : ''}`;
      }

      return 'Searching...';
    },
    checkboxes() {
      return CHECKBOX_DATA;
    },
    disableNext() {
      return this.page >= this.pageCount;
    },
    disablePrev() {
      return this.page === 1;
    },
    disableType() {
      return this.serviceTypes.length === 0;
    },
    noResultsBody() {
      if (this.errorMessage) {
        return `The message returned was: ${this.errorMessage}`;
      }
      return 'Try adjusting your search or filters to find what you’re looking for.';
    },
    noResultsHeader() {
      if (this.errorMessage) {
        return 'Unable to retrieve a result';
      }
      return 'No results found';
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.perPage);
    },
    serviceTypes() {
      if (this.selectedServiceCategory) {
        return this.selectedServiceCategory.types;
      }
      return [];
    },
    sortOptions() {
      return SORT_OPTIONS;
    },
    totalResults() {
      return this.searchMeta ? this.searchMeta.available_count : 0;
      // return this.searchMeta ? this.searchMeta.total_count : 0;
    },
  },
  watch: {
    selectedSortBy() {
      this.updateUrl();
      this.setResults();
      this.page = 1;
    },
    page() {
      this.updateUrl();
      this.setResults();
    },
  },
  methods: {
    setResults() {
      // prettier-ignore
      const {
        page,
        perPage,
        selectedSortBy: sortBy = DEFAULT_SORT,
        services,
      } = this;

      const items = [...(services ?? [])];

      const compareDistance = (a, b) => {
        const calc = (a.distanceValue ?? 9999) - (b.distanceValue ?? 9999);
        return calc;
      };

      if (sortBy === 'distance') items.sort(compareDistance);
      // console.log({ sortBy, items });

      const offset = perPage * (page - 1);
      const sliceStart = offset;
      const sliceEnd = sliceStart + perPage;
      const paginatedItems = items.slice(sliceStart, sliceEnd);
      // console.log({ paginatedItems, offset, perPage });

      this.orderedResults = paginatedItems;
      this.filterFeaturedResults();
    },
    createQParam() {
      const keywords = [];
      if (this.keyword) {
        keywords.push(this.keyword);
      }
      if (this.servicesCheckboxes.includes('alcohol-services')) {
        keywords.push('alcohol');
      }
      if (this.servicesCheckboxes.includes('opioid-services')) {
        keywords.push('opioids');
      }
      if (this.servicesCheckboxes.includes('stimulant-services')) {
        keywords.push('stimulants');
      }
      if (this.servicesCheckboxes.includes('ethnicity')) {
        keywords.push('Aboriginal Torres Strait Islanders');
      }
      if (this.servicesCheckboxes.includes('diverse')) {
        keywords.push('Culturally and linguistically diverse');
      }
      if (this.servicesCheckboxes.includes('lgbtiq')) {
        keywords.push('LGBTIQ');
      }
      if (keywords.length === 0) {
        keywords.push('alcohol drugs');
      }
      return keywords.join(',');
    },
    /**
     * Create a DistanceMatrixService instance.
     */
    getDistanceService() {
      this.distanceService = new window.google.maps.DistanceMatrixService();
    },
    /**
     * Update page and reload data
     */
    getPage(increment, event) {
      this.page += increment;
      event.currentTarget.blur();
      this.scrollToResults();
    },
    scrollToResults() {
      const el = this.$refs.results;
      if (!el) return;

      const { top } = el.getBoundingClientRect();
      const scrollPos = window.scrollY + top;
      window.scrollTo({
        top: scrollPos,
        left: 0,
        behavior: 'smooth',
      });
    },
    newFilter() {
      this.page = 1;
      this.submit();
    },
    newSearch() {
      this.page = 1;
      this.servicesCheckboxes = [];
      this.submit();
      this.scrollToResults();
    },
    /**
     * Construct dummy page link for crawling
     */
    pageLink(increment) {
      const queryString = new URLSearchParams();
      if (this.keyword) {
        queryString.set('q', this.keyword);
      }
      if (this.selectedServiceCategory) {
        queryString.set('service_category', this.selectedServiceCategory.label);
      }
      if (this.selectedServiceType) {
        queryString.set('service_type', this.selectedServiceType.label);
      }

      const newPage = this.page + increment;
      if (newPage > 1) {
        queryString.set('page', newPage);
      }

      return !queryString.length ? window.location.pathname : `?${queryString.toString()}`;
    },
    resetForm() {
      this.hasSearch = false;
      this.keyword = null;
      this.location = null;
      this.urlLocation = null;
      this.page = 1;
      this.selectedServiceCategory = '';
      this.selectedServiceType = '';
      this.selectedSortBy = DEFAULT_SORT;
      this.searchMeta = null;
      this.services = [];
      this.orderedResults = [];
      this.servicesCheckboxes = [];
      this.updateUrl();
    },
    resetType() {
      this.selectedServiceType = '';
    },
    setCheckboxes(state) {
      this.expandCheckboxes = state;
    },
    filterFeaturedResults() {
      this.filteredFeaturedResults = this.featuredSupportServices.filter((item) => {
        if (item.service_category !== this.selectedServiceCategory.label) return false;
        if (!!item.address_state && item.address_state !== this.searchMeta.location.state) {
          return false;
        }
        return true;
      });
    },
    /**
     * Submit query to backend. Returns request Promise in preparation for scroll
     * action when results are populated.
     * @return { Promise }
     */
    async submit() {
      // The value doesn't actually matter here. Just need a state change
      // to trigger watch() in child components
      this.loading = true;
      this.forceClose = !this.forceClose;
      this.searchMeta = null;

      const queryParams = new URLSearchParams();

      const serviceType = this.selectedServiceType.value || this.selectedServiceCategory.value;
      queryParams.set('service_type_raw', serviceType);
      queryParams.set('area', this.location);
      queryParams.set('q', this.createQParam());

      if (this.servicesCheckboxes.includes('youth')) {
        queryParams.set('age_groups', 'earlyadolescent,midadolescent,lateadolescent,youngadult');
      }
      if (this.servicesCheckboxes.includes('wheelchair')) {
        queryParams.set('accessibility', 'fullaccess');
      }

      queryParams.set('catchment', 'prefer');

      this.updateUrl();

      // Load the first page quickly
      await submitSearch(this, queryParams, true).then(() => {
        this.setResults();
      });

      // Then load the rest
      await submitSearch(this, queryParams, false).then(() => {
        // Update the results, but disable the auto-scroll
        this.setResults();
      });

      this.loading = false;
    },
    /**
     * Push submit params to URL
     */
    updateUrl() {
      const urlParams = new URLSearchParams();

      if (this.keyword) {
        urlParams.set('q', this.keyword);
      }
      if (this.location) {
        urlParams.set('area', this.location);
      }
      if (this.selectedServiceCategory) {
        urlParams.set('service_category', this.selectedServiceCategory.label);
      }
      if (this.selectedServiceType) {
        urlParams.set('service_type', this.selectedServiceType.label);
      }
      if (this.selectedSortBy && this.selectedSortBy !== DEFAULT_SORT) {
        urlParams.set('sort', this.selectedSortBy);
      }

      if (this.page > 1) {
        urlParams.set('page', this.page);
      }

      const queryString = urlParams.toString();
      const path = queryString ? `?${queryString}` : window.location.pathname;

      window.history.pushState({}, '', path);
    },
    /**
     * Update data from URL params
     */
    updateFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);

      const keyword = urlParams.get('q');
      if (keyword) {
        this.keyword = keyword;
      }

      const serviceCategory = urlParams.get('service_category');
      if (serviceCategory) {
        this.selectedServiceCategory = this.categories.find(
          (category) => category.label === serviceCategory,
        );
      }

      const serviceType = urlParams.get('service_type');
      if (serviceType) {
        this.selectedServiceType = this.selectedServiceCategory.types.find(
          (type) => type.label === serviceType,
        );
      }

      const sortBy = urlParams.get('sort');
      if (sortBy) {
        this.selectedSortBy = sortBy;
      }

      const location = urlParams.get('area');
      if (location) {
        this.urlLocation = location;
        this.location = location;
      }

      const urlPage = Number.parseInt(urlParams.get('page'), 10);
      if (urlPage && urlPage > 1) {
        this.page = urlPage;
      }

      if (location && serviceCategory) {
        this.submit();
      }
    },
    updateLocation(value) {
      this.location = value;
    },
  },
};
</script>
