<template>
  <div class="sidenav-container" :class="{ 'sidenav-hidden': headerHidden }">
    <div class="sidenav__header is-hidden-tablet">
      <span>{{ headerTitle }}</span>
      <button
        class="sidenav-header__cross"
        :class="{ 'is-active': sidenavExpanded }"
        title="Expand child pages navigation"
        aria-controls="child-pages-navigation"
        :aria-expanded="ariaExpanded"
        @click="toggleSidenav"
      >
        <span class="nav_menu__cross--horizontal"></span>
        <span class="nav_menu__cross--vertical"></span>
      </button>
    </div>
    <ul class="sidenav" :class="{ 'expanded': sidenavExpanded }">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Sidenav',
  props: {
    headerTitle: String,
  },
  data() {
    return {
      sidenavExpanded: false,
    };
  },
  computed: {
    ariaExpanded() {
      return this.sidenavExpanded ? 'true' : 'false';
    },
    headerHidden() {
      return this.$store.state.navbarHidden;
    },
  },
  methods: {
    /**
     * Expand the sidenav on mobile views
     */
    toggleSidenav() {
      this.sidenavExpanded = !this.sidenavExpanded;

      const page = document.scrollingElement;
      if (!this.sidenavExpanded) {
        page.classList.remove('locked');
      } else {
        page.classList.add('locked');
      }
    },
  },
};
</script>
