<template>
  <li class="drugs__item" v-if="isFiltered">
    <a :href="fact.url" class="drugs__drug" :class="{ 'is-marginless': featured }">
      <div class="drugs__icon" role="presentation">
        <div
          class="drugs__image"
          :style="`background-image: url('${drugImage}');`"
          role="presentation"
        ></div>
      </div>
      <h3 class="drugs__name" :class="drugFact">
        <span>{{ fact.title }}</span>
      </h3>
    </a>
  </li>
</template>

<script>
export default {
  name: 'drug-fact',
  props: {
    featured: Boolean,
    filter: String,
    fact: Object,
  },
  computed: {
    drugImage() {
      if (this.fact.header_image) {
        return this.fact.header_image.url;
      }
      return '';
    },
    drugFact() {
      try {
        const drugType = this.fact.drug.drug_type.name.toLowerCase();
        return `drugs__name--${drugType}`;
      } catch (error) {
        return '';
      }
    },
    isFiltered() {
      if (!this.fact.drug) {
        return false;
      }
      const lowerFilter = this.filter.toLowerCase();
      const isMatch = !this.filter || lowerFilter === this.fact.drug.name.toLowerCase()
      || this.fact.drug.drug_effects.some((effect) => effect.name.toLowerCase() === lowerFilter)
      || (this.fact.drug.drug_type && this.fact.drug.drug_type.name.toLowerCase() === lowerFilter)
      || this.fact.drug.street_names.some(
        (streetName) => streetName.name.toLowerCase() === lowerFilter,
      );
      return isMatch;
    },
  },
  mounted() {
    this.$emit('loaded');
  },
};
</script>
