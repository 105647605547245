<template>
  <article class="resource" ref="resource">
    <header class="columns resource__header is-gapless is-marginless">
      <div class="column is-9">
        <p class="resource__location">{{ location }}</p>
        <h2 class="title is-2 resource__title">{{ websiteTitle }}</h2>
        <h3 class="resource__organisation" :class="{ 'resource-hidden': collapsed }">
          {{ organisation }}
        </h3>
      </div>
      <div class="column has-text-right-tablet is-flex-mobile">
        <div class="resource__star-rating">
          <template v-for="i in 5">
            <star-icon :key="i" :className="starIcon(i, score)"></star-icon>
          </template>
        </div>
        <p class="resource__reviewer is-hidden-mobile is-small">Reviewed by ADF</p>
        <p class="resource__reviewer is-hidden-tablet is-small">ADF reviewed:</p>
        <p class="resource__last-reviewed is-small is-hidden-mobile">{{ lastReviewed }}</p>
        <p class="resource__last-reviewed is-small is-hidden-tablet">{{ shortenedDate }}</p>
      </div>
    </header>
    <div class="resource__body">
      <div class="resource__body-main">
        <div
          class="resource__summary rich-text"
          :class="{ 'resource-hidden': collapsed }"
          v-html="summary"
        ></div>
        <a
          :href="websiteUrl"
          class="button is-red is-large"
          :class="{ 'resource-hidden': collapsed }"
        >Visit website</a>
        <a
          :href="websiteUrl"
          class="resource__link is-hidden-mobile"
          :class="{ 'resource-hidden': collapsed }"
        >{{ websiteUrl }}</a>
      </div>

      <div class="resource__scorebreakdown" :class="{ 'resource-hidden': collapsed }">
        <div class="resource__star-rating is-hidden-tablet">
          <template v-for="i in 5">
            <star-icon :key="i" :className="starIcon(i, score)"></star-icon>
          </template>
        </div>
        <h3 class="resource__section-title">Score breakdown</h3>
        <a v-if="ratingLink" :href="ratingLink" class="resource__criteria-link">More info</a>
        <ul class="resource__criterias is-flex-mobile">
          <li class="resource__criteria">
            <span>
              Authorship
              <span class="is-hidden-mobile">(24%)</span>
            </span>
            <div class="resource__criteria-stars">
              <template v-for="i in 5">
                <star-icon :key="i" :className="starIcon(i, breakdown.authorship)"></star-icon>
              </template>
            </div>
            <p class="is-hidden-tablet is-small has-text-grey-dark">(worth 24%)</p>
          </li>
          <li class="resource__criteria">
            <span>
              Relevance
              <span class="is-hidden-mobile">(33%)</span>
            </span>
            <div class="resource__criteria-stars">
              <template v-for="i in 5">
                <star-icon :key="i" :className="starIcon(i,breakdown.relevance)"></star-icon>
              </template>
            </div>
            <p class="is-hidden-tablet is-small has-text-grey-dark">(worth 33%)</p>
          </li>
          <li class="resource__criteria">
            <span>
              Accuracy
              <span class="is-hidden-mobile">(43%)</span>
            </span>
            <div class="resource__criteria-stars">
              <template v-for="i in 5">
                <star-icon :key="i" :className="starIcon(i, breakdown.reliability)"></star-icon>
              </template>
            </div>
            <p class="is-hidden-tablet is-small has-text-grey-dark">(worth 43%)</p>
          </li>
        </ul>
      </div>

      <div
        v-if="tags.length > 0"
        :class="{ 'resource-hidden': collapsed }"
        class="is-small resource__tags is-flex-tablet"
      >
        <h3 class="resource__section-title">Tags</h3>
        <div class="resources__tag-items">
          <template v-for="(tag, index) in tags">
          <button
            class="resource__tag"
            :aria-label="ariaLabel(tag)"
            :key="tag"
            @click.prevent="updateSearch(tag)"
          >
            <span>{{ tag }}</span>
            <template v-if="index !== tags.length - 1">, </template>
          </button>
        </template>
        </div>
      </div>

    </div>
    <footer class="columns resource__footer is-gapless">
      <div class="column is-10"
          v-if="badges.length > 0">
        <ul
          class="resource__badges is-flex-tablet"
        >
          <li v-for="badge in badges" :key="badge" class="resource__badge">
            <span
              v-if="badgeIcons[badge]"
              class="resource__badge_icon icon"
              :class="badgeIcons[badge]"
            ></span>
            {{ badge }}
          </li>
        </ul>
      </div>
      <div class="column">
        <div class="resource__button-container">
          <button
            class="resource__size_toggle"
            :class="{ 'is-active': !collapsed }"
            @click="toggleResourceSize"
          >
            <span>{{ buttonText }}</span>
            <div class="icon"><arrow-icon></arrow-icon></div>

          </button>
        </div>
      </div>
    </footer>
  </article>
</template>

<script>
import { sendGaEvent } from '@/utils';
import ArrowIcon from '../svg/ArrowIcon.vue';
import StarIcon from '../svg/StarIcon.vue';

export default {
  name: 'resource',
  components: {
    ArrowIcon,
    StarIcon,
  },
  props: {
    badges: Array,
    lastReviewed: String,
    location: String,
    organisation: String,
    ratingLink: String,
    score: Number,
    summary: String,
    tags: Array,
    websiteTitle: String,
    websiteUrl: String,
    breakdown: Object,
    slug: String,
  },
  data() {
    return {
      collapsed: true,
    };
  },
  mounted() {
    if (this.slug === this.uniqueSlug) {
      this.toggleResourceSize();
    }
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.searchTerm;
      },
      set(value) {
        this.$store.commit('setSearchTerm', value);
      },
    },
    uniqueSlug: {
      get() {
        return this.$store.state.uniqueSlug;
      },
      set(value) {
        this.$store.commit('setUniqueSlug', value);
      },
    },
    buttonText() {
      return this.collapsed ? 'Expand' : 'Collapse';
    },
    shortenedDate() {
      return this.lastReviewed.substr(this.lastReviewed.indexOf(' ') + 1);
    },
    badgeIcons() {
      return {
        'Academic Institution': 'icon--academic',
        'Alcohol Industry': 'icon--alcohol',
        'Community-led': 'icon--community',
        'Govt. Affiliation': 'icon--government',
        'Paywall or Login': 'icon--paywall',
        'Pharmaceutical Industry': 'icon--pharmaceutical',
        'Tobacco Industry': 'icon--tobacco',
        'Religious Affiliation': 'icon--religious',
      };
    },
  },

  methods: {
    /**
     * return the class required for the star rating in the position
     * @param { Int } pos
     * @returns { String } either 'star--full', 'star--half' or 'star--empty'
     */
    starIcon(pos, data) {
      if (data >= pos) {
        return 'star--full';
      }
      if (data === pos - 0.5) {
        return 'star--half';
      }
      return 'star--empty';
    },
    /**
     * Toggle the height of a resource article
     * Sends event to GA if expanded
     */
    toggleResourceSize() {
      this.collapsed = !this.collapsed;
      if (!this.collapsed) {
        sendGaEvent('DID', 'expand', this.websiteTitle);
      }
    },
    /**
     * Update searchTerm with tag and Emit search
     */
    updateSearch(tag) {
      this.searchTerm = tag;
      this.$emit('search');
    },
    /**
     * Return text for aria-label attribute
     */
    ariaLabel(tag) {
      return `filter results by ${tag}`;
    },
  },
};
</script>
