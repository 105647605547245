import { render, staticRenderFns } from "./ResourceIndex.vue?vue&type=template&id=1e8a8ec2&"
import script from "./ResourceIndex.vue?vue&type=script&lang=js&"
export * from "./ResourceIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports