<template>
  <header class="header" :class="{ 'menu-hidden': headerHidden }">
    <alert-bar v-if="alerts.length" :alerts="alerts" />
    <div class="container">
      <nav class="nav" :class="{ 'menu-active': burgerActive }">
        <div class="nav__logo">
          <a href="/">
            <img
              src="@/images/adf-logo.svg"
              alt="ADF Logo"
              width="301"
              height="58"
              loading="lazy"
            />
          </a>
        </div>
        <button
          class="nav_burger"
          :class="{ 'is-active': burgerActive }"
          aria-controls="navbar-menu-tier-primary navbar-menu-tier-secondary navbar-menu-tier-help"
          :aria-expanded="ariaExpanded"
          title="toggle main menu"
          @click="toggleMenus"
        >
          <span class="nav_burger__line nav_burger__line--top" aria-hidden="true"></span>
          <span class="nav_burger__line nav_burger__line--middle" aria-hidden="true"></span>
          <span class="nav_burger__line nav_burger__line--bottom" aria-hidden="true"></span>
        </button>
        <ul
          class="nav_menu nav_menu--primary"
          :class="{ 'menu-hidden': menuHidden }"
          id="navbar-menu-tier-primary"
          @transitionend="hideMenus"
        >
          <slot name="primary"></slot>
        </ul>
        <ul
          class="nav_menu nav_menu--secondary"
          :class="{ 'menu-hidden': menuHidden }"
          id="navbar-menu-tier-secondary"
          @transitionend="hideMenus"
        >
          <slot name="secondary"></slot>
        </ul>
        <ul
          class="nav_menu nav_menu--help"
          :class="{ 'menu-hidden': menuHidden }"
          id="navbar-menu-tier-help"
          @transitionend="hideMenus"
        >
          <li class="nav_menu__item">
            <a href="#" id="recitemeLaunch" class="nav_menu__link">
              <span class="nav_menu__link_text">Accessibility</span>
              <span class="nav__icon icon--accessibility">
                <accessibility-icon></accessibility-icon>
              </span>
            </a>
          </li>
          <slot name="tertiary"></slot>
          <li class="nav_menu__item">
            <form class="nav__form" action="/search/" method="GET">
              <input
                v-show="searchActive"
                class="nav__input"
                type="text"
                name="search"
                aria-label="Search website"
                @blur="searchActive = false"
                ref="navbarSearch"
              />
              <a
                v-show="!searchActive"
                class="nav_menu__link"
                tabindex="0"
                @keyup.enter="showSearch"
                @click.prevent="showSearch"
              >
                <span class="nav_menu__link_text">Search</span>
              </a>
              <button type="submit" class="nav__form-button" aria-label="Go to search page">
                <span class="nav__icon icon--search">
                  <search-icon></search-icon>
                </span>
              </button>
            </form>
          </li>
        </ul>
        <div class="nav__contact is-promo">
          <span id="mobile" v-if="isMobile">ALCOHOL & DRUG HOTLINE</span>
          <span id="desktop" v-else>NATIONAL ALCOHOL & OTHER DRUG HOTLINE:</span>
          <a class="nav__contact_number" :href="contactLink">{{ contact }}</a>
          <img
            src="@/images/icons/phone-white.svg"
            alt=""
            role="presentation"
            width="15"
            height="15"
            loading="lazy"
            class="nav__phone"
          />
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import AccessibilityIcon from '@/components/svg/AccessibilityIcon.vue';
import SearchIcon from '@/components/svg/SearchIcon.vue';
import AlertBar from './AlertBar.vue';

export default {
  name: 'Navbar',
  components: { SearchIcon, AccessibilityIcon, AlertBar },
  props: {
    contact: String,
    alerts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      burgerActive: false,
      menuHidden: true,
      prevScrollTop: 0,
      searchActive: false,
      isMobile: false,
    };
  },
  computed: {
    ariaExpanded() {
      return this.burgerActive ? 'true' : 'false';
    },
    contactLink() {
      return `tel:${this.contact}`;
    },
    headerHidden() {
      return this.$store.state.navbarHidden;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.toggleNav);

    if (window.innerWidth <= 720) {
      this.isMobile = true;
    }
  },
  methods: {
    toggleNav() {
      /**
       * Shows/Hides the Navbar on page scroll
       */
      const scrollTop = window.pageYOffset;
      const elHeight = this.$el.getBoundingClientRect().height;

      // Ignore small scroll changes, or if menu is expanded
      if (Math.abs(this.prevScrollTop - scrollTop) <= 5 || this.burgerActive) {
        return;
      }

      // If we're scrolling down and more than 1.5 header heights
      // away from the page top, hide the navbar
      if (this.prevScrollTop < scrollTop && scrollTop > elHeight * 1.5 && !this.headerHidden) {
        this.$store.dispatch('setNavbarHidden', true);

        // If we're scrolling up, show the navbar
      } else if (this.prevScrollTop > scrollTop && this.headerHidden) {
        this.$store.dispatch('setNavbarHidden', false);
      }

      this.prevScrollTop = scrollTop;
    },
    toggleMenus() {
      /**
       * Begin the menu transition when burger is clicked
       */
      if (this.burgerActive) {
        this.burgerActive = false;
      } else {
        this.menuHidden = false;
        this.burgerActive = true;
      }
    },
    hideMenus() {
      /**
       * If transitioning out, hide only when the transition is complete
       */
      if (!this.burgerActive) {
        this.menuHidden = true;
      }
    },
    showSearch() {
      this.searchActive = true;
      this.$nextTick().then(() => {
        this.$refs.navbarSearch.focus();
      });
    },
  },
};
</script>
