<template>
  <div class="location-search">
    <div class="location-search__header">
      <label
        :for="labelId"
        :class="{ 'is-sr-only': hideLabel }"
      >Location<sup>*</sup></label>
    </div>
    <input
      required
      :id="labelId"
      type="text"
      name="location"
      :aria-label="placeholder"
      :placeholder="placeholder"
      class="input is-grey-dark"
      v-model="searchTerm"
      @input="getPredictions()"
      @blur="setInput()"
    >
    <ul v-if="autocompleteVisible" class="location-search__autocomplete">
      <li v-for="prediction in predictions" :key="prediction.place_id">
        <a
          href="#"
          class="location-search__result"
          v-html="prediction.description"
          @click.prevent="setPrediction(prediction)"
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce } from '@/utils';
import axios from 'axios';

const debounced = debounce(300);

export default {
  name: 'GoogleLocationSearch',
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
    labelId: {
      type: String,
      required: true,
    },
    overrideValue: String,
    placeholder: String,
  },
  mounted() {
    const localThis = this;
    this.checkingInterval = setInterval(() => {
      if (window.google) {
        localThis.googleLoaded = true;
      }
    }, 10);
  },
  data() {
    return {
      predictions: [],
      searchTerm: '',
      service: null,
      showAutocomplete: false,
      googleLoaded: false,
      checkingInterval: null,
    };
  },
  watch: {
    overrideValue(newValue) {
      this.searchTerm = newValue;
    },
    /**
     * Hack to watch for Google script being loaded.
     */
    googleLoaded() {
      clearInterval(this.checkingInterval);
      this.service = new window.google.maps.places.AutocompleteService();
      this.$emit('maps-loaded');
    },
  },
  computed: {
    autocompleteVisible() {
      return this.searchTerm.length > 0 && this.predictions.length > 0 && this.showAutocomplete;
    },
    serviceParamObject() {
      return {
        input: this.searchTerm,
        componentRestrictions: {
          country: 'au',
        },
        types: ['(regions)'],
      };
    },
  },
  methods: {
    titleCase(str) {
      const splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i += 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    },
    /**
     * Get predictions from Google Places API
     */
    getPredictions() {
      if (!this.service) return;
      this.$emit('update-location', this.searchTerm);
      debounced().then(() => {
        axios.get('https://PQNWJCXMZG-dsn.algolia.net/1/indexes/adf_suburbs', {
          params: {
            query: this.serviceParamObject.input,
          },
          headers: {
            'Content-Type': 'application/json',
            'X-Algolia-Application-Id': 'PQNWJCXMZG',
            'X-Algolia-API-Key': 'f4db19c6077d445d05a57d64b69d762f',
          },
        })
          .then((response) => {
            if (response.data && response.data.hits && response.data.hits.map) {
              this.predictions = response.data.hits.map((result) => ({
                description: `<span>${this.titleCase(result.locality.toLowerCase())}</span>, ${result.state} ${result.postcode}`,
                value: `${this.titleCase(result.locality.toLowerCase())}, ${result.state} ${result.postcode}`,
              }));
            } else {
              this.predictions = [];
            }
            this.showAutocomplete = true;
          })
          .catch((error) => {
            console.log(`Error retrieving predictions: ${error}`);
            this.predictions = [];
            this.showAutocomplete = false;
          });
      });
    },
    /**
     * Set input if not completed via autoselect
     * Runs if autocomplete selected as well, but blur resolves before click
     */
    setInput() {
      this.$emit('update-location', this.searchTerm);
    },
    /**
     * Update parent form with autocomplete selection
     */
    setPrediction(prediction) {
      let text = prediction.value.split(', Australia')[0];
      // If there is a trailing postcode, remove it
      const match = text.match(/ \d{4}$/);
      if (match) {
        [text] = text.split(match[0]);
      }
      this.searchTerm = text;
      this.$emit('update-location', text);
      this.showAutocomplete = false;
    },
  },
};
</script>
