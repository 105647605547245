<template>
  <div>
    <insight-filters
      :categories="categories"
      :featuredCategories="featuredCategories"
      @category-update="updateFilter"
    ></insight-filters>
    <div class="insight-wrapper">
      <section class="section insight-section" v-for="(insight, idx) in insightChunks" :key="idx">
        <div class="tile insights-tile is-ancestor">
          <div class="tile insights-tile is-4 is-vertical-desktop is-parent">
            <insight
              v-if="insight[0]"
              tilesize="1x1"
              :delay="1"
              :insight="insight[0]"
            ></insight>
            <insight
              v-if="insight[1]"
              tilesize="2x1"
              :delay="2"
              :insight="insight[1]"
            ></insight>
          </div>
          <div class="tile insights-tile is-8 is-vertical-desktop">
            <div class="tile insights-tile is-parent">
              <insight
                v-if="insight[2]"
                tilesize="2x2"
                :delay="3"
                :insight="insight[2]"
              ></insight>
            </div>
            <div class="tile insights-tile is-parent">
              <insight
                v-if="insight[3]"
                tilesize="1x1"
                :delay="4"
                :insight="insight[3]"
              ></insight>
              <insight
                v-if="insight[4]"
                tilesize="1x1"
                :delay="5"
                :insight="insight[4]"
              ></insight>
            </div>
          </div>
        </div>

        <div class="tile insights-tile is-ancestor">
          <div class="tile insights-tile is-8 is-vertical-desktop is-parent">
            <insight
              v-if="insight[5]"
              tilesize="2x2"
              :delay="6"
              :insight="insight[5]"
            ></insight>
            <insight
              v-if="insight[6]"
              tilesize="1x2"
              :delay="7"
              :insight="insight[6]"
            ></insight>
          </div>
          <div class="tile insights-tile is-vertical-desktop is-parent">
            <insight
              v-if="insight[7]"
              tilesize="1x1"
              :delay="8"
              :insight="insight[7]"
            ></insight>
            <insight
              v-if="insight[8]"
              tilesize="2x1"
              :delay="9"
              :insight="insight[8]"
            ></insight>
          </div>
        </div>

        <div class="tile insights-tile is-ancestor">
          <div class="tile insights-tile is-parent is-vertical-desktop">
            <insight
              v-if="insight[9]"
              tilesize="1x1"
              :delay="10"
              :insight="insight[9]"
            ></insight>
            <insight
              v-if="insight[10]"
              tilesize="1x1"
              :delay="11"
              :insight="insight[10]"
            ></insight>
          </div>
          <div class="tile insights-tile is-parent">
            <insight
              v-if="insight[11]"
              tilesize="2x1"
              :delay="12"
              :insight="insight[11]"
            ></insight>
          </div>
          <div class="tile insights-tile is-vertical-desktop is-parent">
            <insight
              v-if="insight[12]"
              tilesize="1x1"
              :delay="13"
              :insight="insight[12]"
            ></insight>
            <insight
              v-if="insight[13]"
              tilesize="1x1"
              :delay="14"
              :insight="insight[13]"
            ></insight>
          </div>
        </div>
      </section>
      <button class="button" @click="loadData">Load more</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Insight from './Insight.vue';
import InsightFilters from './InsightFilters.vue';

const FEATURED_LIMIT = 8;
const LIMIT = 56;

export default {
  name: 'insight-index',
  components: { Insight, InsightFilters },
  props: {
    categories: Array,
    featuredCategories: Array,
  },
  data() {
    return {
      insights: [],
      featuredInsights: [],
      offset: 0,
      featuredOffset: 0,
      selectedCategoryId: null,
      loading: true,
    };
  },
  computed: {
    insightChunks() {
      const chunks = [];
      let chunk = [];
      let featuredCount = 0;
      for (let i = 0; i < this.insights.length; i += 1) {
        if ( // if it's the 2nd or 5th element insert a featured tile
          (chunk.length % 14 === 2 || chunk.length % 14 === 5)
          && this.featuredInsights.length > featuredCount
        ) {
          chunk.push(this.featuredInsights[featuredCount]);
          featuredCount += 1;
        }

        chunk.push(this.insights[i]);
        if (chunk.length === 14) {
          chunks.push(chunk);
          chunk = [];
        }
      }
      if (chunk.length > 0) {
        chunks.push(chunk);
      }
      return chunks;
    },
  },
  mounted() {
    this.getQuery();
  },
  methods: {
    /**
     * Load data for page. Waits for results of both calls to make sure everything ends up
     * in the right place.
     */
    loadData() {
      this.loading = true;
      const query = this.selectedCategoryId ? `&categories=${this.selectedCategoryId}` : '';
      const featured = axios.get(`${process.env.VUE_APP_API_URL}insights/?${query}&featured=true&limit=${FEATURED_LIMIT}&offset=${this.featuredOffset}`);
      const nonfeatured = axios.get(`${process.env.VUE_APP_API_URL}insights/?${query}&featured=false&limit=${LIMIT}&offset=${this.offset}`);

      Promise
        .all([featured, nonfeatured])
        .then(([featuredResults, otherResults]) => {
          this.featuredInsights = this.featuredInsights.concat(featuredResults.data.items);
          this.featuredOffset += FEATURED_LIMIT;
          this.insights = this.insights.concat(otherResults.data.items);
          this.loading = false;
          this.offset += LIMIT;
        })
        .catch((error) => {
          console.error(`Error getting insight data: ${error}`);
        });
    },
    /**
     * Gets Query from url
     */
    getQuery() {
      const params = new URLSearchParams(window.location.search);
      if (params.get('category')) {
        const categoryObj = this.categories.find(
          (category) => category.name === params.get('category'),
        );
        this.selectedCategoryId = categoryObj.id || '';
      }
      this.loadData();
    },
    /**
     * Changes the url query string when the category select is changed
     */
    setUrl() {
      if (this.selectedCategoryId) {
        const params = new URLSearchParams();
        const categoryObj = this.categories.find(
          (category) => category.id === this.selectedCategoryId,
        );
        if (categoryObj) params.set('category', categoryObj.name);
        window.history.replaceState({}, '', `?${params.toString()}`);
      } else {
        window.history.replaceState({}, '', window.location.pathname);
      }
    },
    /**
     * Reset all values to initial values (run before filtering)
     */
    resetInsights() {
      this.insights = [];
      this.offset = 0;
      this.featuredOffset = 0;
      this.featuredInsights = [];
    },
    /**
     * filter page by category
     */
    updateFilter(payload) {
      this.selectedCategoryId = payload;
      this.resetInsights();
      this.setUrl();
      this.loadData();
    },
  },
};
</script>
