<template>
  <li class="nav_submenu__item">
    <a class="nav_submenu__link" :href="url">
      <span>{{ title }}</span>
    </a>
  </li>
</template>

<script>
export default {
  name: 'navbar-submenu-item',
  props: {
    title: String,
    url: String,
  },
};
</script>
