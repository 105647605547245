<template>
  <div>
    <label id="pathways-progress" class="progress-bar__status">{{ percent }}% complete</label>
    <div
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      :aria-valuenow="percent"
      aria-labelledby="pathways-progress"
      class="progress-bar__background"
    >
      <span class="progress-bar__slider" :style="elementWidth"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PathwaysProgressBar',
  computed: {
    elementWidth() {
      return {
        width: `${this.percent}%`,
      };
    },
    percent() {
      return this.$store.getters['pathways/progressPercent'];
    },
  },
};
</script>
