<template>
  <div class="resources__refine">
    <div class="columns is-centered">
      <div class="column is-10">
        <fieldset>
          <legend class="has-text-weight-bold">Only show organisations that are:</legend>
          <div class="refine-items is-flex">
            <label
              v-for="(badge, index) in biasedBadges"
              :key="index"
              class="refine-item"
            >
              <input
                type="checkbox"
                v-model="selectedBadges"
                :value="index"
              >
              <span>{{ badge }}</span>
              <span class="icon icon--check"></span>
            </label>
          </div>
        </fieldset>

        <fieldset>
          <legend class="has-text-weight-bold">
            Targeted towards the following communities:
          </legend>
          <div class="refine-items is-flex">
            <label
              v-for="community in communities.value"
              :key="community.id"
              class="refine-item"
            >
              <input
                type="checkbox"
                :value="community.id"
                v-model="selectedCommunities"
              >
              <span>{{ community.name }}</span>
              <span class="icon icon--check"></span>
            </label>
          </div>
        </fieldset>
        <div class="refine-buttons is-flex">
          <button
            class="button is-red"
            @click="updateSelectedFilters()"
          >
            Apply
          </button>
          <button class="button" @click="resetTempFilters">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'resources-refine-filter',
  props: {
    communities: Object,
    biasedBadges: Object,
  },
  data() {
    return {
      tempBadges: Object,
      tempCommunities: Object,
    };
  },
  mounted() {
    this.tempCommunities = this.selectedCommunities;
    this.tempBadges = this.selectedBadges;
  },
  computed: {
    selectedCommunities: {
      get() {
        return this.$store.state.selectedCommunities;
      },
      set(value) {
        this.$store.commit('setSelectedCommunities', value);
      },
    },
    selectedBadges: {
      get() {
        return this.$store.state.selectedBadges;
      },
      set(value) {
        this.$store.commit('setSelectedBadges', value);
      },
    },
  },
  methods: {
    updateSelectedFilters() {
      this.$emit('toggle');
      this.$emit('search');
    },
    resetTempFilters() {
      this.selectedCommunities = this.tempCommunities;
      this.selectedBadges = this.tempBadges;
      this.$emit('toggle');
    },
  },
};
</script>
