/* eslint-disable no-shadow */
import alcoholSrc from '@/assets/pathways/pw_alcohol.png';
import cannabisSrc from '@/assets/pathways/pw_cannabis.png';
import cocaineSrc from '@/assets/pathways/pw_cocaine.png';
import empathogensSrc from '@/assets/pathways/pw_empathogens.png';
import inhalantsSrc from '@/assets/pathways/pw_inhalants.png';
import opioidsSrc from '@/assets/pathways/pw_opioids.png';
import otherSrc from '@/assets/pathways/pw_other.png';
import psychedelicsSrc from '@/assets/pathways/pw_psychedelics.png';
import sedativesSrc from '@/assets/pathways/pw_sedatives.png';
import stimulantsSrc from '@/assets/pathways/pw_stimulants.png';

const BRANCH = 'PathwaysQuestionBranch';

const DRUG_SELECTION = 'PathwaysQuestionDrugOptions';

const SELF_AGE = 'PathwaysQuestionSelfAge';
const SELF_USAGE = 'PathwaysQuestionSelfUsage';
const SELF_DESIRE = 'PathwaysQuestionSelfDesire';
const SELF_PROBLEMS = 'PathwaysQuestionSelfProblems';
const SELF_IMPAIR = 'PathwaysQuestionSelfImpair';
const SELF_CONCERNED = 'PathwaysQuestionSelfConcerned';
const SELF_DRUG_CONTROL = 'PathwaysQuestionSelfDrugControl';
const SELF_INJECTION = 'PathwaysQuestionSelfInjection';

const OTHER_AGE = 'PathwaysQuestionOtherAge';
const OTHER_USAGE = 'PathwaysQuestionOtherUsage';
const OTHER_IMPAIR = 'PathwaysQuestionOtherImpair';
const OTHER_CONCERNED = 'PathwaysQuestionOtherConcerned';
const OTHER_DRUG_CONTROL = 'PathwaysQuestionOtherDrugControl';
const OTHER_INJECTION = 'PathwaysQuestionOtherInjection';
const LOWER_USAGE = 'PathwaysQuestionLowerUsage';
const DISCUSSION = 'PathwaysQuestionDiscussion';
const GUIDANCE = 'PathwaysQuestionGuidance';
const RESULTS = 'PathwaysQuestionResults';

const BASE_TRACK = [BRANCH];

const SELF_TRACK = [
  SELF_AGE,
  DRUG_SELECTION,
  SELF_USAGE,
  SELF_DESIRE,
  SELF_PROBLEMS,
  SELF_IMPAIR,
  SELF_CONCERNED,
  SELF_DRUG_CONTROL,
];

const SELF_INJEC_TRACK = [...SELF_TRACK, SELF_INJECTION];

const OTHER_TRACK = [
  OTHER_AGE,
  DRUG_SELECTION,
  OTHER_USAGE,
  OTHER_IMPAIR,
  OTHER_CONCERNED,
  OTHER_DRUG_CONTROL,
  LOWER_USAGE,
  DISCUSSION,
  GUIDANCE,
];

const OTHER_INJEC_TRACK = [
  OTHER_AGE,
  DRUG_SELECTION,
  OTHER_USAGE,
  OTHER_IMPAIR,
  OTHER_CONCERNED,
  OTHER_DRUG_CONTROL,
  OTHER_INJECTION,
  LOWER_USAGE,
  DISCUSSION,
  GUIDANCE,
];

const DRUG_OPTIONS = [
  {
    data: {
      description: 'Beer, wine, spirits, etc.',
      imgSrc: alcoholSrc,
      label: 'Alcohol',
    },
    link: '/drug-facts/alcohol/',
  },
  {
    data: {
      description: 'Marijuana, pot, grass, hash, etc.',
      imgSrc: cannabisSrc,
      label: 'Cannabis',
    },
    link: '/drug-facts/cannabis/',
  },
  {
    data: {
      description: 'Coke, crack, etc.',
      imgSrc: cocaineSrc,
      label: 'Cocaine',
    },
    link: '/drug-facts/cocaine/',
  },
  {
    data: {
      description: 'Ice, speed, diet pills, etc.',
      imgSrc: stimulantsSrc,
      label: 'Stimulants',
    },
    link: '/drug-facts/stimulants/',
  },
  {
    data: {
      description: 'Nitrous, glue, petrol, paint thinner, etc.',
      imgSrc: inhalantsSrc,
      label: 'Inhalants',
    },
    link: '/drug-facts/inhalants/',
  },
  {
    data: {
      description: 'Valium, Serepax, Rohypnol, etc.',
      imgSrc: sedativesSrc,
      label: 'Sedatives',
    },
    link: '/drug-facts/benzodiazepines/',
  },
  {
    data: {
      description: 'LSD, acid, mushrooms, PCP, ketamine, etc.',
      imgSrc: psychedelicsSrc,
      label: 'Psychedelics',
    },
    link: '/drug-facts/psychedelics/',
  },
  {
    data: {
      description: 'Heroin, morphine, methadone, codeine, etc.',
      imgSrc: opioidsSrc,
      label: 'Opioids',
    },
    link: '/drug-facts/opioids/',
  },
  {
    data: {
      description: 'MDMA, ecstasy, ethylone, PMA, PMMA, etc.',
      imgSrc: empathogensSrc,
      label: 'Empathogens',
    },
    link: '/drug-facts/empathogens/',
  },
  {
    data: {
      description: '',
      imgSrc: otherSrc,
      label: 'Other',
    },
    link: '/drug-facts/new-psychoactive-substances/',
  },
];

export const getters = {
  currentComponent: (state, getters) => {
    if (state.showResults) {
      return RESULTS;
    }
    return getters.track[state.index];
  },
  drugSelections: (state) => {
    if (!state.responses.drugSelection) return [];
    return DRUG_OPTIONS.filter((drug) => state.responses.drugSelection.includes(drug.data.label));
  },
  drugOptions: () => DRUG_OPTIONS,
  isSelfTrack: (state) => state.mode === 'self',
  progressPercent: (state, getters) => {
    const fraction = state.index / (getters.track.length);
    return Math.round(100 * fraction);
  },
  showNotification: (state, getters) => getters.currentComponent === BRANCH,
  showQuestionNavigation: (state, getters) => getters.currentComponent.includes('PathwaysQuestion'),
  track: (state) => {
    const drugs = state.responses.drugSelection || [];
    const includeInjection = drugs.includes('Stimulants') || drugs.includes('Opioids');
    switch (state.mode) {
      case 'self':
        if (includeInjection) {
          return [...BASE_TRACK, ...SELF_INJEC_TRACK];
        }
        return [...BASE_TRACK, ...SELF_TRACK];
      case 'other':
        if (includeInjection) {
          return [...BASE_TRACK, ...OTHER_INJEC_TRACK];
        }
        return [...BASE_TRACK, ...OTHER_TRACK];
      default:
        return BASE_TRACK;
    }
  },
};

export const mutations = {
  resetResponses(state) {
    state.responses = {};
  },
  setIndex(state, payload) {
    state.index = payload;
  },
  setMode(state, payload) {
    state.mode = payload;
  },
  setNextDisabled(state, payload) {
    state.nextDisabled = payload;
  },
  setPageData(state, payload) {
    state.pageData = payload;
  },
  setResponses(state, payload) {
    // Merge objects, with newest response taking precedence
    state.responses = { ...state.responses, ...payload };
  },
  setResults(state, payload) {
    state.results = payload;
  },
  setRisk(state, payload) {
    state.risk = payload;
  },
  setServiceUrl(state, payload) {
    state.serviceUrl = payload;
  },
  setShowResults(state, payload) {
    state.showResults = payload;
  },
  setShowResourcesResults(state, payload) {
    state.showResourcesResults = payload;
  },
};

export const actions = {
  /**
   * Reset questionnaire to initial state
   */
  backToStart({ commit, dispatch }) {
    commit('setIndex', 0);
    commit('setShowResults', false);
    commit('setRisk', '');
    dispatch('updateMode', '');
  },
  /**
   * Calculate score for F&F track
   */
  computeFriendScore({ commit, state, getters }) {
    const results = {};
    let score = 0;
    let unknownCount = 0;

    // Q2
    results.youthServices = state.responses.age === 'under-25';
    // Q4
    switch (state.responses.usage) {
      case 'unknown':
        unknownCount += 1;
        score += 6;
        break;
      case 'daily':
        score += 6;
        break;
      case 'weekly':
        score += 4;
        break;
      case 'monthly':
        score += 3;
        break;
      case 'once-twice':
        score += 2;
        break;
      // no default
    }
    // Q5
    switch (state.responses.impairment) {
      case 'unknown':
        unknownCount += 1;
        score += 8;
        break;
      case 'daily':
        score += 8;
        break;
      case 'weekly':
        score += 7;
        break;
      case 'monthly':
        score += 6;
        break;
      case 'once-twice':
        score += 5;
        break;
      // no default
    }
    // Q6
    switch (state.responses.othersConcerned) {
      case 'in-three-months':
        score += 6;
        break;
      case 'more-three-months':
        score += 3;
        break;
      // no default
    }
    // Q8
    switch (state.responses.injectionUse) {
      case 'in-three-months':
        score += 2;
        results.injectionServices = true;
        break;
      case 'more-three-months':
        score += 1;
        results.injectionServices = true;
        break;
      default:
        results.injectionServices = false;
    }
    let maxDrugScore = 0;

    // Q7
    if (Object.values(state.responses.drugControl).every((response) => response === 'unknown')) {
      unknownCount += 1;
      score += 6;
    } else {
      getters.drugSelections.forEach((drug) => {
        let drugScore = 0;
        switch (state.responses.drugControl[drug]) {
          case 'in-three-months':
            drugScore = 6;
            break;
          case 'more-three-months':
            drugScore = 3;
          // no default
        }
        if (drugScore > maxDrugScore) maxDrugScore = drugScore;
      });
    }
    score += maxDrugScore;

    // Q9
    if (unknownCount < 2 && (state.responses.lowerUsage === 'no' || score < 4)) {
      results.informationServices = true;
    } else if (unknownCount >= 2 || score < 17) {
      results.counsellingServices = true;
    } else {
      // high
      results.rehabilitationServices = true;
      results.counsellingServices = true;
    }

    // Q10
    results.discussionServices = state.responses.discussion === 'no';

    // Q11
    results.healthAdviceServices = state.responses.guidance === 'yes';

    commit('setResults', results);
  },
  /**
   * Calculate score for individual track
   */
  computeIndividualScore({ commit, state }) {
    const results = {};
    let highestRisk = 'low';

    state.responses.drugSelection.forEach((drug) => {
      if (highestRisk === 'high') return; // No point continuing if already worst result
      let score = 0;

      results.youthServices = state.responses.age === 'under-25';

      // Q4
      switch (state.responses.usage[drug]) {
        case 'daily':
          score += 6;
          break;
        case 'weekly':
          score += 4;
          break;
        case 'monthly':
          score += 3;
          break;
        case 'once-twice':
          score += 2;
          break;
        // no default
      }

      // Q5
      switch (state.responses.desire[drug]) {
        case 'daily':
          score += 6;
          break;
        case 'weekly':
          score += 5;
          break;
        case 'monthly':
          score += 4;
          break;
        case 'once-twice':
          score += 3;
          break;
        // no default
      }

      // Q6
      switch (state.responses.problems[drug]) {
        case 'daily':
          score += 7;
          break;
        case 'weekly':
          score += 6;
          break;
        case 'monthly':
          score += 5;
          break;
        case 'once-twice':
          score += 4;
          break;
        // no default
      }

      // Q7
      switch (state.responses.impairment[drug]) {
        case 'daily':
          score += 8;
          break;
        case 'weekly':
          score += 7;
          break;
        case 'monthly':
          score += 6;
          break;
        case 'once-twice':
          score += 5;
          break;
        // no default
      }

      // Q8
      switch (state.responses.othersConcerned[drug]) {
        case 'in-three-months':
          score += 6;
          break;
        case 'more-three-months':
          score += 3;
          break;
        // no default
      }

      // Q9
      switch (state.responses.drugControl[drug]) {
        case 'in-three-months':
          score += 6;
          break;
        case 'more-three-months':
          score += 3;
          break;
        // no default
      }

      if (score > 26) {
        highestRisk = 'high';
      } else if (score > 3) {
        if (drug !== 'Alcohol' || score > 10) {
          highestRisk = 'medium';
        }
      }
    });
    commit('setRisk', highestRisk);

    if (highestRisk === 'low') {
      results.informationServices = true;
    } else if (highestRisk === 'medium') {
      results.counsellingServices = true;
    } else {
      // high
      results.rehabilitationServices = true;
      results.counsellingServices = true;
    }
    // Q9
    results.injectionServices = state.responses.injection && state.responses.injection !== 'never';

    commit('setResults', results);
  },
  /**
   * Move back along question track
   */
  decrementIndex({ commit, state }) {
    if (state.showResults) {
      commit('setShowResults', false);
      commit('setRisk', '');
    } else if (state.index !== 0) {
      commit('setIndex', state.index - 1);
    }
  },
  /**
   * Set clickable state of next button
   */
  disableNext({ commit, state }, payload) {
    if (state.nextDisabled !== payload) {
      commit('setNextDisabled', payload);
    }
  },
  /**
   * Advance along question track
   */
  // eslint-disable-next-line object-curly-newline
  incrementIndex({ commit, dispatch, state, getters }) {
    if (state.index + 1 === getters.track.length) {
      if (getters.isSelfTrack) {
        dispatch('computeIndividualScore');
      } else {
        dispatch('computeFriendScore');
      }
      commit('setShowResults', true);
    } else {
      commit('setIndex', state.index + 1);
    }
  },
  /**
   * Store page data in state
   */
  savePageData({ commit }, payload) {
    commit('setPageData', payload);
  },
  /**
   * Store service url in state
   */
  saveServiceUrl({ commit }, payload) {
    commit('setServiceUrl', payload);
  },
  /**
   * Show resources results
   */
  showResources({ commit, state }) {
    if (state.showResourcesResults !== true) {
      commit('setShowResourcesResults', true);
    }
  },
  /**
   * Show services results
   */
  showServices({ commit, state }) {
    if (state.showResourcesResults !== false) {
      commit('setShowResourcesResults', false);
    }
  },
  /**
   * Set questionaire as self or other track
   */
  updateMode({ commit }, payload) {
    commit('setMode', payload);
    commit('resetResponses');
  },
  /**
   * Store responses from questionnaire
   */
  updateResponses({ commit }, payload) {
    commit('setResponses', payload);
  },
  /**
   * Update results from URL query params
   * @param {URLSearchParams} payload
   */
  updateResultsFromURL({ commit }, payload) {
    const results = {};
    const riskLevel = payload.get('level');
    if (riskLevel === '1' || riskLevel === '2' || riskLevel === '3') {
      const riskMap = { 1: 'low', 2: 'medium', 3: 'high' };
      commit('setRisk', riskMap[riskLevel]);
      commit('setMode', 'self');
    }
    const selectedDrugs = payload.get('drugs');
    if (selectedDrugs) {
      commit('setResponses', { drugSelection: selectedDrugs.split(',') });
    }
    if (payload.get('youth') === 'true') {
      results.youthServices = true;
    }
    if (payload.get('injection') === 'true') {
      results.injectionServices = true;
    }
    if (payload.get('info') === 'true') {
      results.informationServices = true;
    }
    if (payload.get('counsel') === 'true') {
      results.counsellingServices = true;
    }
    if (payload.get('rehab') === 'true') {
      results.rehabilitationServices = true;
    }
    if (payload.get('discuss') === 'true') {
      results.discussionServices = true;
    }
    if (payload.get('advice') === 'true') {
      results.healthAdviceServices = true;
    }

    commit('setResults', results);
    commit('setShowResults', true);
  },
};

export const pathwaysModule = {
  namespaced: true,
  state: () => ({
    index: 0,
    mode: null,
    nextDisabled: true,
    pageData: {},
    responses: {},
    results: {},
    risk: '',
    serviceUrl: 'help-support/search/',
    showResults: false,
    showResourcesResults: false,
  }),
  getters,
  mutations,
  actions,
};
