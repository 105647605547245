<template>
  <div class="info-box">
    <div class="rich-text" v-html="content"></div>
    <a
      v-if="link"
      :href="link"
      class="info-box__cta"
      data-test="infobox-link"
      >{{ linkText }}</a
    >
  </div>
</template>

<script>
export default {
  name: 'info-box',
  props: {
    content: String,
    link: String,
    linkText: {
      type: String,
      default: 'Find out more',
    },
  },
};
</script>
