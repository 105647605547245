<template>
  <button
    class="button is-print"
    :class="[colorVariants, navbarPosClass]"
    title="Print page"
    @click.prevent="printPage()"
  >
    <print-icon aria-hidden="true" width="13" height="17"></print-icon>
    <span>Print</span>
  </button>
</template>

<script>
import PrintIcon from '@/components/svg/PrintIcon.vue';

export default {
  name: 'PrintButton',
  components: { PrintIcon },
  props: {
    isBlack: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Apply different colour classes based on props
     */
    colorVariants() {
      return {
        'print--black': this.isBlack,
        'print--white': this.isWhite,
      };
    },
    /**
     * Apply a class to set top position of button when sticky and
     * Navbar is active
     */
    navbarPosClass() {
      return {
        'navbar-active': !this.headerHidden,
      };
    },
    headerHidden() {
      return this.$store.state.navbarHidden;
    },
  },
  methods: {
    printPage() {
      window.print();
    },
  },
};
</script>
