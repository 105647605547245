<template>
  <article class="featured-service-result" :class="cardTypeClass">
    <header class="featured-service-result__header">
      <span class="featured-service-result__name" data-test="featured-service-result-name">
        {{ name }}
      </span>
      <h2 class="featured-service-result__title title is-2">{{ title }}</h2>
    </header>
    <div class="featured-service-result__content">
      <p
        class="featured-service-result__description"
        data-test="featured-service-result-description"
      >
        {{ description }}
      </p>
      <a
        v-if="showButton"
        v-bind="linkAttrs"
        class="is-4 button is-red featured-service-result__button"
      >
        <component :is="icon" />
        {{ buttonText }}
      </a>
    </div>
  </article>
</template>

<script>
import PhoneIcon from '../svg/PhoneIcon.vue';
import ExternalLink2Icon from '../svg/ExternalLink2Icon.vue';

export default {
  name: 'FeaturedServiceResult',
  components: {
    PhoneIcon,
    ExternalLink2Icon,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonText() {
      if (this.url) return 'Website';
      if (this.phoneNumber) return this.phoneNumber;
      return false;
    },
    icon() {
      if (this.url) return 'ExternalLink2Icon';
      if (this.phoneNumber) return 'PhoneIcon';
      return false;
    },
    cardTypeClass() {
      if (this.url) return 'is-online';
      if (this.phoneNumber) return 'is-telehealth';
      return '';
    },
    linkAttrs() {
      if (this.url) return { href: this.url, target: '_blank' };
      if (this.phoneNumber) return { href: `tel:${this.phoneNumber}` };
      return false;
    },
    showButton() {
      return this.url || this.phoneNumber || false;
    },
  },
};
</script>
