// eslint-disable no-param-reassign

import Vue from 'vue';
import Vuex from 'vuex';
import { pathwaysModule } from './pathways';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navbarHidden: false,
    selectedAudience: '',
    selectedTopic: '',
    searchTerm: '',
    servicesSearchTerm: '',
    selectedCommunities: [],
    selectedBadges: [],
    sortOrder: '-score',
    uniqueSlug: null,
  },
  mutations: {
    setNavbarHidden(state, payload) {
      state.navbarHidden = payload;
    },
    setSelectedAudience(state, payload) {
      state.selectedAudience = payload;
    },
    setSelectedTopic(state, payload) {
      state.selectedTopic = payload;
    },
    setSearchTerm(state, payload) {
      state.searchTerm = payload;
    },
    setServicesSearchTerm(state, payload) {
      state.servicesSearchTerm = payload;
    },
    setSelectedCommunities(state, payload) {
      state.selectedCommunities = payload;
    },
    setSelectedBadges(state, payload) {
      state.selectedBadges = payload;
    },
    setSortOrder(state, payload) {
      state.sortOrder = payload;
    },
    setUniqueSlug(state, payload) {
      state.uniqueSlug = payload;
    },
  },
  actions: {
    setNavbarHidden(context, payload) {
      if (payload !== context.state.navbarHidden) {
        context.commit('setNavbarHidden', payload);
      }
    },
  },
  modules: {
    pathways: pathwaysModule,
  },
});
