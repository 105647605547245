<template>
  <section class="drug-list">
    <div class="container">
      <h1 class="title is-1 has-text-white drugs__title">{{ title }}</h1>
      <div class="drug__search-container">
        <drug-search
          :currentQuery="filter"
          :resultCount="resultCount"
          :drugs="allDrugs"
          :drugEffects="allDrugEffects"
          :streetNames="allStreetNames"
          @autocomplete-selected="filterSelected"
        ></drug-search>
        <drug-filter :drugTypes="drugTypes" @filter-selected="drugTypeFilter"></drug-filter>
      </div>
      <h2 class="title is-2 has-text-grey-light" v-if="displayFeatured">Featured</h2>
      <ul class="drugs__list" v-if="displayFeatured">
        <drug-fact
          v-for="fact in featured"
          :key="fact.id"
          :fact="fact"
          :filter="filter"
          :featured="true"
        ></drug-fact>
      </ul>
      <ul class="drugs__list" ref="drugFacts">
        <template v-for="fact in combinedDrugData">
          <drug-fact
            v-if="fact.meta.type === 'drug_facts.DrugFactPage'"
            :key="fact.id"
            :fact="fact"
            :filter="filter"
            :featured="false"
            @loaded="updateCount"
          ></drug-fact>
          <drug-type
            v-if="fact.meta.type === 'drug_facts.DrugTypePage'"
            :key="fact.id"
            :fact="fact"
            :filter="filter"
            @loaded="updateCount"
          ></drug-type>
        </template>
      </ul>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { sendGaEvent } from '@/utils';
import DrugFact from './DrugFact.vue';
import DrugType from './DrugType.vue';
import DrugSearch from './DrugSearch.vue';
import DrugFilter from './DrugFilter.vue';

export default {
  name: 'drug-list',
  components: {
    DrugFact,
    DrugType,
    DrugSearch,
    DrugFilter,
  },
  props: ['title', 'pageId'],
  data() {
    return {
      facts: [],
      filter: '',
      featured: [],
      resultCount: 0,
      allDrugs: [],
      allDrugEffects: [],
      allStreetNames: [],
      drugTypes: [],
    };
  },
  computed: {
    displayFeatured() {
      return this.featured.length > 0 && !this.filter;
    },
    combinedDrugData() {
      const combinedDrugData = this.facts.concat(this.drugTypesPages);

      return combinedDrugData.sort((a, b) => (a.name || a.title).localeCompare(b.name || b.title));
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * Updates `this.facts` facts.
     */
    getData() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}pages/?type=drug_facts.DrugFactPage&fields=id,title,url,drug,header_image,featured&limit=999&order=title`,
        )
        .then((response) => {
          this.facts = response.data.items;
        })
        .catch((error) => {
          console.error('Error fetching facts data:', error);
          this.facts = [];
        });
      axios
        .get(
          `${process.env.VUE_APP_API_URL}pages/?type=drug_facts.DrugTypePage&fields=id,title,url,header_image&limit=999&order=title`,
        )
        .then((response) => {
          this.drugTypesPages = response.data.items;
        })
        .catch((error) => {
          console.error('Error fetching facts data:', error);
          this.drugTypesPages = [];
        });

      axios
        .get(
          `${process.env.VUE_APP_API_URL}pages/?type=drug_facts.DrugFactPage&fields=id,title,url,drug,header_image,featured&limit=999&featured=true&order=title`,
        )
        .then((response) => {
          this.featured = response.data.items;
        })
        .catch((error) => {
          console.error('Error fetching featured data:', error);
          this.featured = [];
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}drugs/?limit=999`)
        .then((response) => {
          this.allDrugs = response.data.items;
        })
        .catch((error) => {
          console.error('Error fetching all drugs data:', error);
          this.allDrugs = [];
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}drug-effects/?limit=999`)
        .then((response) => {
          this.allDrugEffects = response.data.items;
        })
        .catch((error) => {
          console.error('Error fetching all drug effects data:', error);
          this.allDrugEffects = [];
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}street-names/?limit=999`)
        .then((response) => {
          this.allStreetNames = response.data.items;
        })
        .catch((error) => {
          console.error('Error fetching all street names data:', error);
          this.allStreetNames = [];
        });

      axios
        .get(`${process.env.VUE_APP_API_URL}drugtypes/`)
        .then((response) => {
          this.drugTypes = response.data.items;
        })
        .catch((error) => {
          console.error('Error fetching drug types data:', error);
          this.drugTypes = [];
        });

      const params = new URLSearchParams(window.location.search);
      const urlQuery = params.get('query');
      if (urlQuery) {
        this.filterSelected(urlQuery);
      }
    },
    /**
     * Update query when user types in search and update result count
     */
    filterSelected(payload) {
      this.filter = payload;
      this.updateCount();
      if (payload) {
        // Don't send analytics when we reset with the empty string
        sendGaEvent('Search', 'drugfacts', payload);
      }
    },
    drugTypeFilter(payload) {
      this.filter = payload;
      this.updateCount();
    },
    updateCount() {
      this.$nextTick(() => {
        this.resultCount = this.$refs.drugFacts.children.length;
      });
    },
  },
};
</script>
